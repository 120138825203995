import { siteApi } from "@api";
import { BalanceBadge, RHFController } from "@components";
import { deployEnvConfig } from "@configs/deploy-env";
import { MIN_WITHDRAWAL_AMOUNT } from "@constants";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAuthSession } from "@hooks";
import useProfileBalance from "@hooks/useProfileBalance";
import useRHF from "@hooks/useRHF";
import { CountryCurrency } from "@types";
import BigNumber from "bignumber.js";
import { Button, DrawerTitle, NumberField, bnOrZero, cn, delay, fNumber, useHandleApiResponse } from "kz-ui-sdk";
import { useCallback, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { z as zod } from "zod";
import RangeButton from "../RangeButton";

const currency = {
  [CountryCurrency.PH]: "Peso",
  [CountryCurrency.TH]: "Baht",
};

const currentCurr = currency[deployEnvConfig.country.currency];
const minAmountText = fNumber(MIN_WITHDRAWAL_AMOUNT, 2);

const schema = zod.object({
  amount: zod
    .string({
      required_error: "Amount is required",
    })
    .refine((val) => bnOrZero(val).gte(MIN_WITHDRAWAL_AMOUNT), {
      message: `Minimum amount is ${minAmountText} ${currentCurr}`,
    }),
});

type Values = zod.infer<typeof schema>;

interface WithdrawFormProps {
  onSuccess: () => void;
  onUpdateAmount: (amount: BigNumber) => void;
}

export default function WithdrawForm({ onSuccess, onUpdateAmount }: WithdrawFormProps) {
  const { balance, refetch: refetchBalance } = useProfileBalance();
  const { handleApiResponse } = useHandleApiResponse({
    toast,
  });
  const [withdraw, { isLoading }] = siteApi.useWithdrawMutation();
  const { t } = useTranslation();
  const { bankAccount } = useAuthSession();

  const maxBalance = useMemo(() => balance.integerValue(BigNumber.ROUND_DOWN), [balance]);

  const [direction, setDirection] = useState<"range" | "input">("range");

  const [range, setRange] = useState(1);

  const onSubmit = useCallback(
    async (formValues: Values) => {
      if (!bankAccount) return toast.error(t("Lacking of bank account"));
      if (balance.lt(formValues.amount)) return toast.error(t("Insufficient balance"));
      const withdrawRes = await withdraw({
        ...formValues,
        amount: bnOrZero(formValues.amount).toNumber(),
      });
      onUpdateAmount(bnOrZero(formValues.amount));
      handleApiResponse(withdrawRes, {
        onSuccess: () => {
          onSuccess();
          delay(1000).then(() => {
            refetchBalance();
          });
        },
      });
    },

    [bankAccount, t, balance, withdraw, handleApiResponse, onSuccess, refetchBalance, onUpdateAmount],
  );

  const { control, submit, watch, setValue, formState } = useRHF(
    {
      amount: maxBalance.toString(),
    },
    zodResolver(schema),
    onSubmit,
  );

  const amount$ = watch("amount");

  useEffect(() => {
    if (direction === "range") {
      if (!maxBalance.eq(0)) {
        const amountValue = maxBalance.times(range).integerValue(BigNumber.ROUND_DOWN).toString();
        setValue("amount", amountValue);
      } else {
        setRange(0);
      }
    } else {
      setRange(Math.min(Number(BigNumber(amount$).div(maxBalance).toFixed(2)), 1));
    }
  }, [maxBalance, direction, setValue, range, amount$]);

  return (
    <div>
      <DrawerTitle
        title={t("Withdraw")}
        size="lg"
      />
      <span
        className={cn("text-content-base mb-1 block text-sm", {
          "content-negative-tertiary": !!formState?.errors?.amount,
        })}
      >
        {t("Deposit Amount")}
      </span>
      <div className="flex flex-col gap-4 pb-1">
        <RHFController
          control={control}
          name="amount"
          onChange={() => {
            setDirection("input");
          }}
        >
          <NumberField
            variant="currency"
            allowDecimals={false}
            allowNegativeValue={false}
            disabled={isLoading}
            currency={t(deployEnvConfig.country.currency)}
          />
        </RHFController>
        {/*  */}
        <RangeButton
          value={range}
          onClick={(v) => {
            setDirection("range");
            setRange(v);
          }}
          disabled={isLoading}
        />
        {/*  */}
        <div className="mt-[11px] flex flex-row items-center justify-between gap-x-2">
          <span className="text-content-secondary text-sm">{t("Available credit")}</span>
          <BalanceBadge />
        </div>

        <Button
          disabled={!bankAccount}
          size="lg"
          onClick={submit}
          loading={isLoading}
        >
          {t("Next withdraw")}
        </Button>
      </div>
    </div>
  );
}
