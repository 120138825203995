import { IconNavDeposit, IconNavHome, IconNavPrizes, IconNavProfile, IconNavWithdraw } from "@assets";
import { CoinHeartbeat, openDeposit, openWithdraw } from "@components";
import { Paths } from "@constants";
import { usePromotionStatus } from "@hooks";
import { getThemeAsset } from "@utils";
import {
  BottomNavigationAction,
  BottomNavigation as BottomNavigationUIKit,
  PropsWithClassName,
  WarningWrapper,
  cn,
} from "kz-ui-sdk";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

interface BottomNavigationProps extends PropsWithChildren, PropsWithClassName {}

const BottomNavigation = ({ className }: BottomNavigationProps) => {
  const { t } = useTranslation();

  const { isAnyClaimable: isPromotionsClaimable } = usePromotionStatus();

  return (
    <>
      <BottomNavigationUIKit className={cn(className)}>
        {/*HOME*/}
        <NavLink
          to={Paths.PRIVATE.HOME}
          className="h-[56px] w-[56px] flex-shrink-0"
        >
          {({ isActive }) => (
            <BottomNavigationAction
              icon={
                <IconNavHome
                  className={isActive ? "text-content-base" : "text-content-secondary"}
                  width={20}
                  height={20}
                />
              }
              label={t("Home")}
              active={isActive}
              className="w-full"
            />
          )}
        </NavLink>

        {/*PRIZES*/}
        <NavLink
          to={Paths.PRIVATE.PRIZES}
          className="h-[56px] w-[56px] flex-shrink-0"
        >
          {({ isActive }) => (
            <>
              <BottomNavigationAction
                icon={
                  <WarningWrapper
                    isShown={isPromotionsClaimable ?? false}
                    classes={{
                      "span&": "right-[-4px] top-[-2px] !h-[16px] !w-[16px] !text-[10px] border-2 border-[#291c5a]",
                    }}
                    icon={
                      <CoinHeartbeat
                        className="absolute -right-1 -top-1"
                        imgClassName="h-[13px] w-[13px]"
                      />
                    }
                  >
                    <IconNavPrizes
                      width={20}
                      height={20}
                      className={isActive ? "text-content-base" : "text-content-secondary"}
                    />
                  </WarningWrapper>
                }
                label={t("Prizes")}
                className="h-[56px] w-[56px]"
                active={isActive}
              />
            </>
          )}
        </NavLink>

        {/*DEPOSIT*/}
        <button
          onClick={openDeposit}
          className="group inline-block h-[60px] w-[60px] translate-y-[-10px]"
        >
          <div
            className={cn(
              "bg-bottomNavBtnRound relative h-full w-full rounded-full shadow-[0px_4px_6px_0px_rgba(0,_0,_0,_0.24)] transition-transform will-change-transform active:translate-y-0.5",
            )}
          >
            {/*COLOR MIST BG*/}
            <div
              className="absolute left-0 top-0 h-full w-full animate-spin rounded-full will-change-transform [animation-duration:16s]"
              style={{
                backgroundImage: `url(${getThemeAsset("misc/bg-nav-center.png")})`,
              }}
            />

            <div className="absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center">
              <IconNavDeposit
                className="absolute bottom-[10px]"
                width={60}
                height={60}
              />
              <span className="text-content-base absolute bottom-[14px] text-xs font-bold">{t("Deposit")}</span>
            </div>

            {/*RIM LIGHT*/}
            <div
              className={`bg-bottomNavBtnRoundRimLight absolute left-0 top-0 h-full w-full rounded-full border-0 border-dashed border-[rgba(0,0,0,0.20)] shadow-[inset_0px_2px_1px_0px_rgba(255,255,255,0.24),_inset_0px_4px_4px_0px_rgba(255,255,255,0.25)]`}
            />
            {/*GRAY OVERLAY*/}
            <div className={`absolute left-0 top-0 h-full w-full rounded-full bg-[rgba(217,217,217,0.01)]`} />
          </div>
        </button>

        {/*WITHDRAW*/}
        <BottomNavigationAction
          icon={<IconNavWithdraw />}
          label={t("Withdraw")}
          onClick={openWithdraw}
          className="text-content-secondary active:text-content-base h-[56px] w-[56px]"
          classes={{
            "label&": "active:!text-content-base",
          }}
        />

        <NavLink
          to={Paths.PRIVATE.PROFILE}
          className="h-[56px] w-[56px] flex-shrink-0"
        >
          {({ isActive }) => (
            <BottomNavigationAction
              icon={
                <IconNavProfile
                  width={22}
                  height={22}
                  className={isActive ? "text-content-base" : "text-content-secondary"}
                />
              }
              label={t("Profile")}
              active={isActive}
              className="w-full"
            />
          )}
        </NavLink>
      </BottomNavigationUIKit>
    </>
  );
};

export default BottomNavigation;
