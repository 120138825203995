import { MdArrowForward } from "react-icons/md";
import { ButtonIcon } from "kz-ui-sdk";
import { ReactNode } from "react";

interface ProfileMenuButtonProps {
  icon: ReactNode;
  label: string;
  onClick?: () => void;
}

const ProfileMenuButton = ({ icon, label, onClick }: ProfileMenuButtonProps) => {
  return (
    <div
      role="button"
      onClick={onClick}
      className="flex h-12 items-center gap-x-3 rounded-md px-4 transition-opacity duration-200 active:bg-gray-600/10"
    >
      {icon}
      <span className="text-content-base text-sm font-bold">{label}</span>
      <ButtonIcon
        size="sm"
        icon={<MdArrowForward className="scale-[0.8]" />}
        className="ml-auto"
        variant="ghost"
      />
    </div>
  );
};

export default ProfileMenuButton;
