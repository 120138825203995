import { IconMaintenance } from "@assets";
import { cn, PropsWithClassName } from "kz-ui-sdk";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

export type MaintenanceOverlaySize = "md" | "lg";
interface MaintenanceOverlayProps extends PropsWithChildren, PropsWithClassName {
  underMaintenance?: boolean;
  size?: MaintenanceOverlaySize;
  text?: string;
}

const MaintenanceOverlay = ({
  underMaintenance,
  children,
  className,
  size = "md",
  text = "Maintenance",
}: MaintenanceOverlayProps) => {
  const { t } = useTranslation();
  if (!underMaintenance) {
    return children;
  }

  const handleOnClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <>
      {children}
      <div
        className={cn(
          "absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center bg-black/80 p-1.5 text-center",
          className,
        )}
        onClick={handleOnClick}
      >
        <IconMaintenance className={cn(ICON_SIZE_CLASSES[size])} />
        <p
          className={cn("text-center text-[10px] font-semibold leading-3 text-content-base", LABEL_SIZE_CLASSES[size])}
        >
          {t(text)}
        </p>
      </div>
    </>
  );
};

const ICON_SIZE_CLASSES: Record<MaintenanceOverlaySize, string> = {
  lg: "h-8 w-8",
  md: "h-6 w-6",
};

const LABEL_SIZE_CLASSES: Record<MaintenanceOverlaySize, string> = {
  lg: "text-xs",
  md: "text-[10px]",
};

export default MaintenanceOverlay;
