import { IconDeposit2, IconMSIAccountBalanceWallet, IconMSIJoyStick, IconMSIMoveItem, IconWithdraw2 } from "@assets";
import { BackButton, DeferNavLink, UsernameBadge } from "@components";
import { openDeposit } from "@components/utils/DepositDrawer";
import { openWithdraw } from "@components/utils/WithdrawDrawer";
import { Paths } from "@constants";
import { useAuthSession } from "@hooks";
import { getThemeAsset } from "@utils";
import { ProfileMenuButton } from "@views/ProfilePage/components";
import { Button, Drawer, PageTitle, delay } from "kz-ui-sdk";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdHistory, MdInfo, MdOutlineLock, MdOutlinePublic } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const navList = [
  {
    label: "Bank Account",
    link: Paths.PRIVATE.BANK_ACCOUNTS,
    icon: <IconMSIAccountBalanceWallet className="text-primary-light" />,
  },
  {
    label: "Transactions History",
    link: Paths.PRIVATE.TRANSACTION,
    icon: (
      <MdHistory
        className="text-primary-light"
        size={24}
      />
    ),
  },
  {
    label: "Betting History",
    link: Paths.PRIVATE.BET_HISTORY,
    icon: <IconMSIJoyStick className="text-primary-light" />,
  },
  {
    label: "Change PIN",
    link: Paths.PRIVATE.UPDATE_PIN,
    icon: (
      <MdOutlineLock
        className="text-primary-light"
        size={24}
      />
    ),
  },
  {
    label: "Language",
    link: Paths.PRIVATE.LANGUAGE,
    icon: (
      <MdOutlinePublic
        className="text-primary-light"
        size={24}
      />
    ),
  },
  {
    label: "Terms & Conditions",
    link: Paths.PRIVATE.TERMS_CONDITIONS,
    icon: (
      <MdInfo
        className="text-primary-light"
        size={24}
      />
    ),
  },
];

const ProfilePage = () => {
  const { logout } = useAuthSession();
  const [isShowOptions, setIsShowOptions] = useState(false);
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const { t } = useTranslation();

  const handleCloseLogoutConfirm = useCallback(() => {
    setIsShowOptions(false);
  }, []);

  const handleOpenLogoutConfirm = useCallback(() => {
    setIsShowOptions(true);
  }, []);

  return (
    <div className="flex flex-col">
      <PageTitle
        label={t("My profile")}
        backButton={<BackButton onClick={goBack} />}
      />
      <div className="mx-auto mb-3">
        <UsernameBadge />
      </div>
      <div className="mb-4 flex flex-row gap-2.5">
        <Button
          variant="primary"
          classes={{
            "label&": "text-xs gap-1",
            "root&": "!rounded-md",
          }}
          icon={<IconDeposit2 />}
          onClick={() => delay().then(openDeposit)}
        >
          {t("Deposit")}
        </Button>
        <Button
          variant="secondary"
          classes={{
            "label&": "text-xs gap-1",
            "root&": "!rounded-md",
          }}
          icon={<IconWithdraw2 />}
          onClick={() => delay().then(openWithdraw)}
        >
          {t("Withdraw")}
        </Button>
      </div>
      {navList &&
        navList.map((nav, index) => (
          <DeferNavLink
            key={nav.link}
            className="mb-1 w-auto"
            to={nav.link}
          >
            <ProfileMenuButton
              icon={nav.icon}
              label={t(nav.label)}
            />
          </DeferNavLink>
        ))}

      <div className="mt-7">
        <ProfileMenuButton
          icon={<IconMSIMoveItem className="text-primary-light" />}
          onClick={handleOpenLogoutConfirm}
          label={t("Log Out")}
        />
      </div>

      <Drawer
        open={isShowOptions}
        onClose={handleCloseLogoutConfirm}
        placement="bottom"
        floatingHandle
        safeZone
        classes={{
          "container&": "!px-0",
        }}
      >
        <div className="flex h-full flex-col items-center">
          {/*  */}
          <div
            className="h-[235px] w-full bg-cover bg-center bg-no-repeat"
            style={{
              backgroundImage: `url(${getThemeAsset("drawer-headers/logout-door.png")})`,
            }}
          ></div>
          <div className="bg-dialog flex w-full flex-col items-center">
            <div className="text-content-base mb-6 mt-8 inline-block max-w-[310px] text-base">
              {t("Do you want to log out")}
            </div>
            <div className="w-full px-5 pb-16">
              <Button
                variant="primary"
                size="lg"
                onClick={() => delay(200).then(logout)}
              >
                {t("Log Out")}
              </Button>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default ProfilePage;
