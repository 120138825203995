import { siteApi } from "@api";
import { IconNotePad2 } from "@assets";
import { BackButton } from "@components";
import { t } from "i18next";
import { EmptyState, HTMLSanitizer, PageTitle, Skeleton } from "kz-ui-sdk";
import { useMemo } from "react";
import "./html-styles.css";

export const TNCPage = () => {
  const { data, isFetching } = siteApi.useGetTermConditionsQuery();

  const content = useMemo(() => {
    return data?.entries?.text;
  }, [data?.entries?.text]);

  return (
    <div className="flex flex-col">
      <PageTitle
        label={t("Terms & Conditions")}
        backButton={<BackButton />}
        className="mb-4"
      />

      {!content && !isFetching && (
        <EmptyState
          icon={<IconNotePad2 className="text-content-secondary" />}
          title={t("Terms & Conditions")}
          subTitle={t("is being updated.")}
        />
      )}

      {!isFetching && (
        <HTMLSanitizer
          html={content}
          className="term-section animate-fade-in"
        />
      )}

      {isFetching && (
        <Skeleton
          className="mb-6 !h-36"
          count={4}
        />
      )}
    </div>
  );
};

export default TNCPage;
