import { authApi, publicApi } from "@api";
import { FormOTP, FormVerifyPIN } from "@components";
import { deployEnvConfig } from "@configs/deploy-env";
import { OnboardMode, Paths } from "@constants";
import auth from "@stores/auth";
import { clearReferralCode, getReferralCode } from "@utils";
import { LoginSubPage } from "@views/LoginPage/types";
import { APIErrorCodes, delay, standardizePhoneNumber, useHandleApiResponse } from "kz-ui-sdk";
import { PropsWithChildren, useCallback, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { generatePath, useNavigate } from "react-router-dom";

interface FormOTPAndPIN extends PropsWithChildren {
  phone: string;
  onComplete?: (values: { phone: string; otp: string; pin: string }) => void;
  mode: OnboardMode;
  deferUpdateToken?: boolean;
}

// referral errors
const REFERRAL_ERROR_CODES: string[] = [
  APIErrorCodes.Member.AlreadyReferral,
  APIErrorCodes.Member.NotFoundReferralCode,
  APIErrorCodes.Member.ChainReferralLoop,
  APIErrorCodes.Member.MaxReferralReached,
  APIErrorCodes.Member.SelfReferralError,
];

const FormOTPAndPIN = ({ phone, mode, deferUpdateToken = false }: FormOTPAndPIN) => {
  const [subPage, setSubPage] = useState<LoginSubPage>(LoginSubPage.OTP);
  const [OTPValue, setOTPValue] = useState("");
  const [PINValue, setPINValue] = useState("");
  const [errorMsgOTP, setErrorMsgOTP] = useState("");

  const [login, { isLoading: isLoggingIn }] = authApi.useLoginMutation();
  const [verifyOTP, { isLoading: isVerifyingOTP }] = publicApi.useVerifyOTPMutation();

  const { handleApiResponse } = useHandleApiResponse({ toast });

  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleChangeSubPage = (step: LoginSubPage) => {
    setSubPage(step);
  };

  const handleOnOTPComplete = async (code: string) => {
    setOTPValue(code);
    await handleVerifyOTP(code);
  };

  const doLogin = useCallback(
    async (pin: string) => {
      toast.dismiss();
      const response = await login({
        client_id: standardizePhoneNumber(phone, deployEnvConfig.country.phoneCode),
        client_secret: pin,
        grant_type: "password",
        account: deployEnvConfig.country.accountId,
      });
      handleApiResponse(response, {
        successMessage: t("Login successfully!"),
        onSuccess: () => {
          if (!response.data) return;
          dispatch(auth.slice.actions.resetExpiredAt());

          let updateToken = () => {
            dispatch(auth.slice.actions.updateAccessToken(response.data));
            navigate(Paths.PRIVATE.HOME, {
              replace: true,
            });
          };

          if (deferUpdateToken) {
            updateToken = () => delay(500).then(() => updateToken());
          }
          updateToken();
        },
      });
    },
    [login, phone, handleApiResponse, t, dispatch, deferUpdateToken, navigate],
  );

  const [submitRegister, { isLoading: isSubmittingRegister }] = publicApi.useSubmitRegisterMutation();

  const handleSubmitNewPIN = useCallback(
    async (pin: string) => {
      if (isSubmittingRegister) return;
      // Only send referral code if mode is register
      const referralCode = mode === OnboardMode.REGISTER ? getReferralCode() : undefined;

      const response = await submitRegister({
        phone: standardizePhoneNumber(phone, deployEnvConfig.country.phoneCode),
        accountId: deployEnvConfig.country.accountId,
        code: OTPValue,
        pin: pin,
        referralCode,
      });

      handleApiResponse(response, {
        successMessage: t(mode === OnboardMode.REGISTER ? "Register successfully!" : "Updated PIN successfully!"),
        toastError: false,
        onSuccess: () => {
          delay().then(() => {
            doLogin(pin);
            // Clear referral code upon use
            if (referralCode) {
              clearReferralCode();
            }
          });
        },
        onError: (error) => {
          // If referral error, show toast error, clear refCode and redirect back to register page
          if (error?.data?.error?.message && REFERRAL_ERROR_CODES.includes(error?.data?.error?.message)) {
            toast.error(t(error?.data?.error?.message));
            clearReferralCode();
            navigate(generatePath(Paths.PUBLIC.ONBOARD, { mode: OnboardMode.REGISTER }), {
              replace: true,
            });
            return;
          }

          setOTPValue("");
          //If PIN is ok but OTP is incorrect, redirect back to OTP form, but keep the PIN
          //User have to re-enter OTP, if correct then log them in immediately
          setPINValue(pin);
          setSubPage(LoginSubPage.OTP);
          setErrorMsgOTP(error?.data?.error?.message ?? "");
        },
      });
    },
    [OTPValue, doLogin, handleApiResponse, isSubmittingRegister, mode, navigate, phone, submitRegister, t],
  );

  const handleVerifyOTP = useCallback(
    async (code: string) => {
      const resVerifyOTP = await verifyOTP({
        phone: standardizePhoneNumber(phone, deployEnvConfig.country.phoneCode),
        code: code,
        accountId: deployEnvConfig.country.accountId,
      });

      handleApiResponse(resVerifyOTP, {
        toastError: false,
        toastSuccess: false,
        onSuccess: () => {
          handleChangeSubPage(LoginSubPage.PIN);
        },
        onError: (error) => {
          if (error?.data?.error?.message) {
            setErrorMsgOTP(t(error?.data?.error.message));
          }
        },
      });
    },
    [handleApiResponse, phone, t, verifyOTP],
  );

  const isFormLoading = useMemo(() => {
    return isLoggingIn || isSubmittingRegister;
  }, [isSubmittingRegister, isLoggingIn]);

  return (
    <div className="mx-auto max-w-full">
      {subPage === LoginSubPage.OTP && (
        <FormOTP
          OTPValue={OTPValue}
          onComplete={handleOnOTPComplete}
          phone={phone}
          errorMessage={errorMsgOTP}
          loading={isVerifyingOTP}
        />
      )}
      {subPage === LoginSubPage.PIN && (
        <FormVerifyPIN
          phone={phone}
          loading={isFormLoading}
          mode={mode}
          onSubmit={(values) => {
            handleSubmitNewPIN(values?.pin as string);
          }}
        />
      )}
    </div>
  );
};

export default FormOTPAndPIN;
