import { publicApi } from "@api";
import { IconMSIPhoneAndroid } from "@assets";
import { BrandLogo, ChatButton } from "@components";
import { deployEnvConfig } from "@configs";
import { LANGUAGES, OnboardMode, Paths, STORAGE_KEY } from "@constants";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAuthSession } from "@hooks";
import { DeployCountry } from "@types";
import { resolveRegisterRequestError } from "@views/LoginPage/utils";
import {
  Button,
  FadeUpSmall,
  LanguageSelector,
  LanguageValue,
  RevealOnScroll,
  TextField,
  cn,
  delay,
  standardizePhoneNumber,
  useDateAvailCheck,
  useHandleApiResponse,
} from "kz-ui-sdk";
import { useCallback, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { z } from "zod";

export type CompleteData = {
  phone: string;
  newMember: boolean;
};

type LoginFormType = {
  phone: string;
};

const PhoneErrorMessages: Record<DeployCountry, string> = {
  [DeployCountry.PH]: "Phone number must be 11 digits",
  [DeployCountry.TH]: "Phone number must be 10 digits",
};

const LoginFormSchema = z.object({
  phone: z
    .string()
    // First digit must be 0
    .regex(/^0/, "phone number should start with 0")
    .regex(deployEnvConfig.country.validators.phone, PhoneErrorMessages[deployEnvConfig.country.deployCountry]),
});

const OnboardingPage = () => {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const { status } = useAuthSession();
  const [createRegisterMutation] = publicApi.useCreateRegisterMutation();

  const { handleApiResponse } = useHandleApiResponse({ toast });
  const { mode: onboardingMode } = useParams() as {
    mode: OnboardMode;
  };
  const [mode, setMode] = useState(onboardingMode ?? OnboardMode.LOGIN);

  const {
    handleSubmit: handlePhoneSubmit,
    register: formPhoneRegister,
    formState: { errors: formPhoneErrors },
  } = useForm<LoginFormType>({
    resolver: zodResolver(LoginFormSchema),
    defaultValues: {
      phone: localStorage.getItem(STORAGE_KEY.LOGIN_PHONE) ?? "",
    },
  });

  const { check: checkRequestOTP } = useDateAvailCheck(
    standardizePhoneNumber(localStorage.getItem(STORAGE_KEY.LOGIN_PHONE) ?? "", deployEnvConfig.country.phoneCode),
  );

  const submitting = useRef(false);

  const onSubmitPhone = async ({ phone }: LoginFormType) => {
    if (submitting.current) return;
    submitting.current = true;
    //
    let allowRedirect = true;
    sessionStorage.setItem(STORAGE_KEY.ONBOARDING_PHONE, phone);
    if (mode === OnboardMode.REGISTER) {
      if (checkRequestOTP()) {
        const createRegisterResponse = await createRegisterMutation({
          phone: standardizePhoneNumber(phone, deployEnvConfig.country.phoneCode),
          accountId: deployEnvConfig.country.accountId,
        });
        handleApiResponse(createRegisterResponse, {
          successMessage: t("OTP sent to your phone!"),
          toastError: false,
          onError: (error) => {
            const message = resolveRegisterRequestError(error, t);
            console.log("error", message);
            if (message) {
              toast.error(message);
            }
          },
        });
        if (createRegisterResponse.error) {
          allowRedirect = false;
        }
      }
    }
    submitting.current = false;
    if (allowRedirect) {
      const redirectUrl = mode === OnboardMode.LOGIN ? Paths.PUBLIC.LOGIN : Paths.PUBLIC.REGISTER;
      navigate(redirectUrl, {
        state: {
          phone,
        },
      });
    }
  };

  const handleOnClickLogin = useCallback(() => {
    setMode(OnboardMode.LOGIN);
    window.history.replaceState(
      null,
      "",
      generatePath(Paths.PUBLIC.ONBOARD, {
        mode: OnboardMode.LOGIN,
      }),
    );
  }, []);

  const handleOnClickRegister = useCallback(() => {
    setMode(OnboardMode.REGISTER);
    window.history.replaceState(
      null,
      "",
      generatePath(Paths.PUBLIC.ONBOARD, {
        mode: OnboardMode.REGISTER,
      }),
    );
  }, []);

  const handleOnChangeLanguage = (option: LanguageValue) => {
    i18n.changeLanguage(option.code).then(() => {});
  };

  return (
    <>
      <div className="mb-4 flex w-full flex-col items-center px-5">
        <RevealOnScroll
          animation={FadeUpSmall}
          triggerOnce
          cascade
          damping={RevealOnScroll.DAMPLING.FASTEST}
          fraction={0.13}
          childClassName="w-full"
          className="w-full"
        >
          <BrandLogo className="mx-auto mb-8 mt-16" />
          <div className="bg-secondary-700 relative flex w-full gap-x-2 rounded-md p-1">
            {/*placeholder*/}
            <div className="absolute left-0 top-0 z-[0] flex h-full w-full items-center justify-center gap-x-2 p-1">
              <div className="text-content-secondary flex-1 text-center">{t("Login")}</div>
              <div className="text-content-secondary flex-1 text-center">{t("Register")}</div>
            </div>
            <Button
              id="btn-login"
              size="md"
              onClick={handleOnClickLogin}
              variant={"primary"}
              className={cn("z-[1] !rounded", {
                "opacity-100": mode === OnboardMode.LOGIN,
                "opacity-0": mode === OnboardMode.REGISTER,
              })}
            >
              {t("Login")}
            </Button>
            <Button
              id="btn-register"
              size="md"
              onClick={handleOnClickRegister}
              variant={"primary"}
              className={cn("relative z-[1] !rounded", {
                "opacity-100": mode === OnboardMode.REGISTER,
                "opacity-0": mode === OnboardMode.LOGIN,
              })}
            >
              {t("Register")}
            </Button>
            <span
              className={cn(
                "absolute right-2 top-1.5 z-10 text-[10px] font-semibold leading-[12px] tracking-[-0.2px] text-[#E8BC89] transition-opacity",
                {
                  "opacity-80": mode === OnboardMode.LOGIN,
                },
              )}
            >
              {t("new user")}
            </span>
          </div>
          <div className="flex w-full flex-col gap-8">
            <div className="mt-6 flex flex-col items-center">
              <form
                onSubmit={handlePhoneSubmit((e) => delay().then(() => onSubmitPhone(e)))}
                className="w-full"
              >
                <TextField
                  {...formPhoneRegister("phone")}
                  error={!!formPhoneErrors.phone}
                  helperText={t(formPhoneErrors.phone?.message as string)}
                  placeholder={t("Phone number")}
                  type={"number"}
                  inputMode={"numeric"}
                  icon={<IconMSIPhoneAndroid className="text-content-base" />}
                  classes={{
                    "input&": "w-full !px-0 text-center",
                    "iconContainer&": "!left-3 !top-[13px]",
                  }}
                  helperTextClasses={{
                    className: "!text-center",
                  }}
                  size="lg"
                />

                <Button
                  id="btn-next"
                  variant="primary"
                  size="lg"
                  type="submit"
                  className="mt-6"
                >
                  {t("Next")}
                </Button>
              </form>
            </div>
          </div>
        </RevealOnScroll>
      </div>

      {/* BOTTOM MENU */}
      <div className="mb-3 mt-auto flex w-full justify-between px-5">
        <LanguageSelector
          languages={LANGUAGES}
          defaultValue={LANGUAGES.find((lang) => lang.code === i18n.language)}
          variant="ghost"
          className="!w-fit max-w-sm"
          size="lg"
          onChange={(option) => handleOnChangeLanguage(option)}
          title={t("Language")}
        />
        <ChatButton />
      </div>
    </>
  );
};

export default OnboardingPage;
