import { cn, PropsWithClassName } from "kz-ui-sdk";
import { AspectRatio } from "react-aspect-ratio";

interface GameEntrySkeletonProps extends PropsWithClassName {
  count?: number;
  variant: "thumbnail" | "row";
}

const GameEntrySkeleton = ({ className, count = 1, variant }: GameEntrySkeletonProps) => {
  if (variant === "row") {
    return Array.from({ length: count }).map((_, index) => (
      <div
        className="flex items-center gap-x-4"
        key={["skeleton-row", index].join("-")}
      >
        <div
          className={cn("bg-darkBrand-900 h-[62px] w-[62px] shrink-0 animate-pulse rounded-[10px]", className)}
        ></div>
        <div className="flex grow flex-col">
          <div className={cn("bg-darkBrand-900 h-[24px] w-full animate-pulse rounded-[10px]", className)}></div>
          <div className={cn("bg-darkBrand-900 mt-1 h-[16px] w-full animate-pulse rounded-[10px]", className)}></div>
        </div>
        <div className="bg-darkBrand-900 h-8 w-8 shrink-0 animate-pulse rounded-full"></div>
      </div>
    ));
  }

  return Array.from({ length: count }).map((_, index) => (
    <AspectRatio
      ratio={1}
      key={["skeleton-thumbnail", index].join("-")}
    >
      <div className={cn("bg-darkBrand-900 aspect-square animate-pulse rounded-[10px]", className)} />
    </AspectRatio>
  ));
};

export default GameEntrySkeleton;
