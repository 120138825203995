import { siteApi } from "@api";
import { IconGame } from "@assets";
import {
  EmptyState,
  GameCategoryModel,
  GameModel,
  GameProviderModel,
  GameStatus,
  GetGameListRequest,
  PropsWithClassName,
  cn,
  useInfiniteQuery,
} from "kz-ui-sdk";
import { CSSProperties, PropsWithChildren, forwardRef, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { GameEntry, GameEntrySkeleton } from "../../components";
import { CATEGORY_POPULAR_SLUG } from "../../contants";
import { useGameGateway } from "../../hooks";

interface GameListProps extends PropsWithChildren, PropsWithClassName {
  className?: string;
  filters?: {
    provider?: GameProviderModel;
    category?: GameCategoryModel;
  };
  style?: CSSProperties;
}

export interface GameListRef {
  fetchNextPage: () => void;
}

const ITEMS_PER_PAGE = 21;
const initialGameQuery: GetGameListRequest["query"] = {
  limit: ITEMS_PER_PAGE,
  offset: 0,
  status: GameStatus.Active,
};

const GameList = forwardRef<GameListRef, GameListProps>(({ style, filters, className }, ref) => {
  const [gameQueryParams, setQueryParams] = useState<GetGameListRequest["query"]>(initialGameQuery);

  const [getGameList] = siteApi.useLazyGetGameListQuery();
  const { open: openGame, isFetching: isOpeningGame, providerMap, openingGame } = useGameGateway();
  const { t } = useTranslation();

  const isSkipQuery = useMemo(() => {
    // Skip query if category is not selected
    if (!gameQueryParams.category) {
      return true;
    }
    //
    // If category is not popular and provider is not selected, skip query
    if (filters?.category?.slug !== CATEGORY_POPULAR_SLUG && !filters?.provider) {
      return true;
    }
  }, [filters?.category?.slug, filters?.provider, gameQueryParams.category]);

  // Fetch game list
  const {
    data: gameQueryData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetchingInitialPage,
  } = useInfiniteQuery<GetGameListRequest, GameModel>(
    getGameList,
    {
      query: gameQueryParams,
    },
    {
      skip: isSkipQuery,
    },
  );

  // Update game list when provider, category change is selected
  useEffect(() => {
    const newQueryParams = buildFilterQueries(initialGameQuery, filters);
    setQueryParams(newQueryParams);
  }, [filters]);

  // Expose fetchNextPage function
  useImperativeHandle(ref, () => ({
    fetchNextPage: () => {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    },
  }));

  const isDisplayProvider = useMemo(() => {
    return !!filters?.category?.slug && !CATEGORY_POPULAR_SLUG.includes(filters?.category?.slug);
  }, [filters?.category?.slug]);

  // Game list to display
  const gameList = useMemo(() => {
    // If category is not popular and has no provider, clear game list
    if (isDisplayProvider && !filters?.provider) {
      return [];
    }
    return gameQueryData;
  }, [filters?.provider, gameQueryData, isDisplayProvider]);

  const isEmptyState = useMemo(() => {
    // If category is not popular and has no provider, display empty state
    if (isDisplayProvider && !filters?.provider) {
      return true;
    }
    return !isFetchingInitialPage && !isFetchingNextPage && gameList.length === 0 && !isSkipQuery;
  }, [filters?.provider, gameList.length, isDisplayProvider, isFetchingInitialPage, isFetchingNextPage, isSkipQuery]);

  const isLoading = useMemo(() => {
    // If category is not popular and has no provider, turn off loading state
    if (isDisplayProvider && !filters?.provider) {
      return false;
    }
    // Show loading state if fetching initial page, fetching next page, or waiting for filters update
    return isFetchingInitialPage || isFetchingNextPage || isSkipQuery;
  }, [isDisplayProvider, filters?.provider, isFetchingInitialPage, isFetchingNextPage, isSkipQuery]);

  return (
    <div
      className={cn("scrollbar-primary", className)}
      style={style}
    >
      <div
        className={cn("grid w-full animate-fade-in grid-cols-3 gap-1.5", {
          "gap-2": !isDisplayProvider,
        })}
      >
        {!isFetchingInitialPage &&
          gameList.map((game, index) => {
            const keyPrefix = filters?.category?.slug === CATEGORY_POPULAR_SLUG ? `recent-${index}` : "";
            const key = [keyPrefix, game.providerRef, game.gameProviderId].filter(Boolean).join("-");

            return (
              <GameEntry
                key={key}
                variant={"thumbnail"}
                game={game}
                onOpen={openGame}
                sourceRef={providerMap[game.gameProviderId]?.sourceRef}
                maintenanceSize={isDisplayProvider ? "md" : "lg"}
                maintenance={game.isMaintenance || providerMap[game.gameProviderId]?.isMaintenance}
              />
            );
          })}

        {isLoading && (
          <GameEntrySkeleton
            variant="thumbnail"
            count={ITEMS_PER_PAGE}
          />
        )}
      </div>

      {/*EMPTY STATE*/}
      {isEmptyState && (
        <EmptyState
          title={t("No games found")}
          icon={<IconGame className="text-content-secondary" />}
          size="sm"
          className="animate-fade-in"
        />
      )}
    </div>
  );
});
GameList.displayName = "GameList";

const buildFilterQueries = (
  defaults: GetGameListRequest["query"],
  filters: GameListProps["filters"],
): GetGameListRequest["query"] => {
  let provider = filters?.provider?.id;
  const category = filters?.category?.id;
  let sortCategory = undefined;

  // If category is recent or popular, set provider to undefined
  if (filters?.category?.slug && CATEGORY_POPULAR_SLUG.includes(filters?.category?.slug)) {
    provider = undefined;
  }

  // If category is popular, should sort games by category
  if (filters?.category?.slug === CATEGORY_POPULAR_SLUG) {
    sortCategory = true;
  }

  return {
    ...defaults,
    provider,
    category,
    sortCategory,
  };
};

export default GameList;
