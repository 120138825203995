import { logger } from "@utils";
import { PropsWithClassName, cn } from "kz-ui-sdk";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";

interface AppVersionProps extends PropsWithClassName {
  format?: string;
  versionFileUrl?: string;
}

const API_URLS: Record<string, string[]> = {
  STAG: ["https://api.test.xo268.win"],
  PROD: ["https://api.xo268.win"],
  DEV: ["http://localhost:8181"],
};

const AppVersion = ({ format = "vYYMMDDHHmm", className }: AppVersionProps) => {
  const [versionNumber, setVersionNumber] = useState<string | undefined>();
  const [serverName, setServerName] = useState<string>();

  const getVersionNumber = useCallback(async () => {
    try {
      const version = import.meta.env.VITE_APP_VERSION;
      const versionNum = moment(version).format(format);
      setVersionNumber(versionNum);
    } catch (error) {
      logger._console.log("Running in development mode");
    }
  }, [format]);

  useEffect(() => {
    getVersionNumber().then(() => {});
  }, [getVersionNumber]);

  useEffect(() => {
    // Only display server name in DEV environment
    if (import.meta.env.DEV) {
      const baseAPI = import.meta.env.VITE_API_BASE_URI;
      const serverName = Object.keys(API_URLS).find((key) => API_URLS[key].includes(baseAPI));
      setServerName(serverName);
    }
  }, []);

  return (
    <div className="pointer-events-none fixed bottom-0 left-1/2 z-[9999] w-full max-w-md -translate-x-1/2">
      <span
        className={cn(
          "absolute bottom-1 right-2.5 origin-bottom-right rotate-90 text-[7px] text-content-secondary [text-shadow:0.25px_0.25px_0px_#000]",
          className,
        )}
      >
        {versionNumber ?? moment().format(format)}
      </span>
      {serverName && (
        <span
          className={cn(
            "absolute bottom-1 left-3 origin-bottom-left -rotate-90 text-[7px] text-content-secondary [text-shadow:0.25px_0.25px_0px_#000]",
            {
              "text-yellow-400": serverName === "STAG",
              "text-red-400": serverName === "PROD",
            },
            className,
          )}
        >
          {serverName}
        </span>
      )}
    </div>
  );
};

export default AppVersion;
