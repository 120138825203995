import { cn } from "kz-ui-sdk";
import React, { DetailedHTMLProps, ImgHTMLAttributes, useState } from "react";

export interface ImageWithFallbackProps
  extends DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  wrapperClassName?: string;
}

type FallbackTextProps = {
  text: string;
};

const FallBackText = ({ text }: FallbackTextProps) => (
  <div className={cn("flex h-full w-full items-center justify-center p-1")}>
    <span className="overflow-hidden text-xs">{text}</span>
  </div>
);

const ImageWithFallback: React.FC<ImageWithFallbackProps> = ({
  src,
  alt,
  className,
  wrapperClassName,
  ...imgProps
}) => {
  const [isError, setIsError] = useState(false);

  const handleError = () => {
    setIsError(true);
  };

  if (!src || isError) {
    return <FallBackText text={alt || "game icon"} />;
  }

  return (
    <div className={cn("relative h-full w-full p-1", wrapperClassName)}>
      <img
        src={src}
        alt={alt}
        onError={handleError}
        className={cn("absolute left-0 top-0 h-full w-full object-fill", className)}
        {...imgProps}
      />
    </div>
  );
};

export default ImageWithFallback;
