import { siteApi } from "@api";
import { PointLabel, PointLabelRef, toast } from "@components";
import { ClaimButtonRef } from "@components/inputs/ClaimButton/ClaimButton.tsx";
import { RewardInfoData } from "@hooks/useGetRewardsInfo.ts";
import { RewardStatus } from "@types";
import { getThemeAsset } from "@utils";
import { AnimKeyframe } from "@views/CashbackPage/types";
import { Card, PropsWithClassName, WalletTxMethod, cn, fAmount, useHandleApiResponse } from "kz-ui-sdk";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AspectRatio } from "react-aspect-ratio";
import { useTranslation } from "react-i18next";
import "./RewardBox.css";
import { BoxBottomSection } from "./components";

const REWARD_THUMBNAIL: Record<WalletTxMethod, string> = {
  [WalletTxMethod.InstantCashBack]: "/images/cashback/cashback-instant.png",
  [WalletTxMethod.DailyCashBack]: "/images/cashback/cashback-daily.png",
  [WalletTxMethod.WeeklyCashBack]: "/images/cashback/cashback-weekly.png",
  [WalletTxMethod.MonthlyCashBack]: "/images/cashback/cashback-monthly.png",
  [WalletTxMethod.DailyBonus]: "/images/cashback/cashback-daily-bonus.png",
};

const REWARD_TITLE: Record<WalletTxMethod, string> = {
  [WalletTxMethod.InstantCashBack]: "instant-cash-back",
  [WalletTxMethod.DailyCashBack]: "daily-cash-back",
  [WalletTxMethod.WeeklyCashBack]: "weekly-cash-back",
  [WalletTxMethod.MonthlyCashBack]: "monthly-cash-back",
  [WalletTxMethod.DailyBonus]: "daily-bonus",
};

interface RewardBoxProps extends PropsWithClassName {
  onClaim?: (type: WalletTxMethod) => void;
  data: RewardInfoData;
  refetch: (type: WalletTxMethod) => Promise<null | RewardInfoData>;
  style?: React.CSSProperties;
}

type TimerType = {
  remainingSeconds: number;
  totalSeconds: number;
  onComplete?: () => void;
};

const RewardBox = ({ data: initialData, refetch, className, style }: RewardBoxProps) => {
  const [data, setData] = useState(initialData);
  const { t } = useTranslation();
  const [claimReward, claimResult] = siteApi.useClaimRewardMutation();
  const { handleApiResponse } = useHandleApiResponse({
    toast,
  });
  const [status, setStatus] = useState(data.initialStatus);
  const [points, setPoints] = useState(data.claimPoints ?? 0);
  const [timer, setTimer] = useState<TimerType>({
    remainingSeconds: 0,
    totalSeconds: 0,
  });
  const [animKeyframe, setAnimKeyframe] = useState<AnimKeyframe>();

  const refPointLabel = useRef<PointLabelRef>(null);
  const refClaimButton = useRef<ClaimButtonRef>(null);

  const onTimerComplete = useCallback(() => {
    setAnimKeyframe("pre-claimable");
    setStatus(RewardStatus.CLAIMABLE);
  }, []);

  // Init state
  useEffect(() => {
    const initialStatus = data.initialStatus;
    const isNoPoints = data.claimPoints === 0;

    // Have no point
    if (isNoPoints) {
      setAnimKeyframe("no-points");
      return;
    }

    // Have points and claimable
    if (initialStatus === RewardStatus.CLAIMABLE) {
      setAnimKeyframe("claimable");
    }

    // Locked
    if (initialStatus === RewardStatus.LOCKED) {
      setAnimKeyframe("locked");
      setTimer({
        totalSeconds: data.periodInSeconds,
        remainingSeconds: data.remainingSeconds,
        onComplete: onTimerComplete,
      });
    }
  }, [data.initialStatus, data.claimPoints, data.remainingSeconds, onTimerComplete, data.periodInSeconds]);

  const handleOnClaim = useCallback(async () => {
    // Step 1. Check minimum points
    if (data.minClaimPoints && data.claimPoints && data.claimPoints < data.minClaimPoints) {
      toast.info(t("minimum amount to claim. play more", { amount: fAmount(data.minClaimPoints) }));
      return;
    }

    // Step 2. Call claim API
    const claimRewardRes = await claimReward({
      query: {
        type: data.type,
      },
    });

    handleApiResponse(claimRewardRes, {
      onSuccess: () => {
        // Step 3. Start claim animation
        setAnimKeyframe("claiming");
        setStatus(RewardStatus.CLAIMED);
        setTimeout(() => {
          refPointLabel.current?.count();
          refClaimButton.current?.renderFlyingCoins();
        }, 1500);
        // Step 4. Check new data and display animation
        refetch(data.type).then((newData: RewardInfoData | null) => {
          setTimeout(() => {
            if (newData) {
              const claimPoints = newData.claimPoints ?? 0;
              setPoints(newData.claimPoints ?? 0);

              // Step 4.1: Display locked status if have points and status is locked
              if (newData.initialStatus === RewardStatus.LOCKED && claimPoints > 0) {
                // Start pre-lock animation
                setAnimKeyframe("pre-lock");
                setTimer({
                  totalSeconds: newData.remainingSeconds,
                  remainingSeconds: newData.remainingSeconds,
                  onComplete: onTimerComplete,
                });
              }
              // Step 4.2: Display zero points if have no points
              if (claimPoints === 0) {
                setAnimKeyframe("no-points");
                setPoints(0);
              }

              // Step 5: Update new data silently
              setTimeout(() => {
                setData(newData);
              }, 2000);
            }
            // Wait for step 3 animation
          }, 6000);
        });
      },
    });
    return;
  }, [claimReward, data.claimPoints, data.minClaimPoints, data.type, handleApiResponse, onTimerComplete, refetch, t]);

  const isClaimable = useMemo(() => {
    // User only can claim when status is claimable and have points
    return status === RewardStatus.CLAIMABLE && points > 0;
  }, [status, points]);

  // Control animation keyframes
  const frameClasses = useMemo(() => {
    return {
      bgWheel: buildAnimKeyframe("reward-box__bg-wheel", animKeyframe),
      bgWheelContainer: buildAnimKeyframe("reward-box__bg-wheel-container", animKeyframe),
      bgSparkles: buildAnimKeyframe("reward-box__bg-sparkles", animKeyframe),
      fgPoints: buildAnimKeyframe("reward-box__fg-points", animKeyframe),
      fgRewardIcon: buildAnimKeyframe("reward-box__fg-reward-icon", animKeyframe),
    };
  }, [animKeyframe]);

  return (
    <AspectRatio ratio="167/180">
      <div
        className={cn(
          "bg-grdSecondary relative isolate overflow-hidden rounded-lg shadow-[0px_8px_24px_0px_rgba(0,_0,_0,_0.25)]",
          className,
        )}
        style={{ ...style, WebkitMaskImage: "-webkit-radial-gradient(white, black)" }}
      >
        <div className="bg-grdSecondary absolute left-0 top-0 h-[20px] w-full"></div>
        {/*BORDER FIXED*/}
        <div className="absolute left-0 top-0 h-full w-full rounded-lg border border-[rgba(255,255,255,0.25)]"></div>

        {/*BORDER YELLOW*/}
        <div
          className={cn(
            "absolute left-1/2 top-1/2 isolate h-[150%] w-[150%] -translate-x-1/2 -translate-y-1/2 transition-opacity !duration-[1.5s] will-change-auto",
            {
              "opacity-0": !isClaimable,
            },
          )}
        >
          <div className="h-full w-full animate-spin bg-[conic-gradient(rgba(217,144,41,0.25),#d18d3b,#d18d3b_49%,rgba(0,_0,_0,_0)_49.1%)] will-change-transform [animation-duration:4s]"></div>
        </div>
        <Card className="relative left-0.5 top-0.5 h-[calc(100%-4px)] w-[calc(100%-4px)] overflow-hidden rounded-md border-none">
          {/*BG WHEEL*/}
          <div
            className={cn(
              "absolute left-1/2 top-1/2 h-[296px] w-[296px] mix-blend-hard-light",
              frameClasses.bgWheelContainer,
            )}
          >
            <img
              src="/images/cashback/bg-reward-rotate.svg"
              className={cn("h-full w-full origin-center will-change-transform", frameClasses.bgWheel)}
              alt=""
            />
          </div>

          {/*LIGHT*/}
          <div className="absolute right-0 top-0 h-full w-full bg-[radial-gradient(125.28%_98.61%_at_49.7%_100%,_#E0C26F_0%,_rgba(224,_194,_111,_0.05)_50%)] opacity-60"></div>

          {/*SPARKLES*/}
          <div className="mix-blend-lighten">
            <div
              className={cn(
                "absolute right-0 top-0 h-full w-full bg-[url(/images/cashback/bg-sparkles.png)] bg-contain will-change-transform",
                frameClasses.bgSparkles,
              )}
            />
          </div>

          {/*REWARD ICON*/}
          <div>
            <div
              className={cn(
                "absolute bottom-[40px] left-1/2 h-[61%] w-[80%] -translate-x-1/2 bg-contain bg-center bg-no-repeat",
                frameClasses.fgRewardIcon,
              )}
              style={{
                backgroundImage: `url(${REWARD_THUMBNAIL[data.type ?? WalletTxMethod.InstantCashBack]})`,
              }}
            />
          </div>
          {/*TITLE*/}
          <div className="absolute left-0 top-1.5 w-full">
            <h2
              className="text-content-base mx-auto text-center text-xl font-bold"
              style={{
                fontFeatureSettings: "'liga' off,'clig' off",
              }}
            >
              {t(REWARD_TITLE[data.type])}
            </h2>
          </div>

          {/*BOTTOM SHINE*/}
          <img
            src={getThemeAsset("/images/cashback/bg-reward-card-shine.svg")}
            alt="shine"
            className={cn(
              "pointer-events-none absolute bottom-[-2px] left-1/2 z-10 -translate-x-1/2 transition-all !duration-[1.5s] will-change-transform",
              {
                "translate-y-8 opacity-0": !isClaimable,
              },
            )}
          />

          {/*POINTS*/}
          {
            <span
              className={cn(
                "reward-box__amount-shadow absolute bottom-[66px] left-1/2 z-10 -translate-x-1/2 text-[22px] font-bold leading-8",
              )}
            >
              <PointLabel
                endVal={0}
                startVal={points || 0}
                type={"down"}
                ref={refPointLabel}
                decimal={2}
                duration={2.5}
                className={cn(frameClasses.fgPoints)}
              />
            </span>
          }
          {/*BOTTOM SECTION*/}
          <BoxBottomSection
            ref={refClaimButton}
            className="absolute bottom-0 w-full"
            onClick={handleOnClaim}
            status={status}
            points={points}
            timer={timer}
            loading={claimResult.isLoading}
          />
        </Card>
      </div>
    </AspectRatio>
  );
};

const buildAnimKeyframe = (className: string, keyframe?: AnimKeyframe) => {
  if (!keyframe) return className;
  return `${className}--${keyframe}`;
};

export default RewardBox;
