import { IconErrorCircle } from "@assets";
import { EmptyState, FundingTxModel } from "kz-ui-sdk";

interface NativeQrProps {
  fundingTx: FundingTxModel;
}

const NativeQr = (props: NativeQrProps) => {
  return (
    <>
      <EmptyState
        icon={<IconErrorCircle className="text-content-secondary" />}
        title={"Sorry"}
        subTitle={"This payment method is not available at the moment."}
      />
    </>
  );
};

export default NativeQr;
