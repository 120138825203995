import { ImageWithFallback, MaintenanceOverlay } from "@components";
import { MaintenanceOverlaySize } from "@components/utils/MaintenanceOverlay";
import { ButtonIcon, GameModel, PropsWithClassName, cn } from "kz-ui-sdk";
import { AspectRatio } from "react-aspect-ratio";
import { MdArrowForward } from "react-icons/md";

interface GameEntryProps extends PropsWithClassName {
  variant: "thumbnail" | "row";
  game: GameModel;
  providerName?: string;
  sourceRef?: string;
  onOpen: (game: GameModel) => void;
  maintenanceSize?: MaintenanceOverlaySize;
  maintenance?: boolean;
  isRecent?: boolean;
}

const GameEntry = ({
  game,
  onOpen,
  variant,
  providerName,
  sourceRef,
  maintenanceSize,
  maintenance,
  isRecent,
}: GameEntryProps) => {
  const handleOnClick = (e: React.MouseEvent) => {
    if (maintenance) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      onOpen?.(game);
    }
  };

  if (variant === "row") {
    return (
      <span
        className="flex items-center gap-x-4 overflow-hidden rounded-[10px]"
        onClick={handleOnClick}
        role={"button"}
      >
        <div className="border-slateBrand-950 bg-secondary-300 relative h-[62px] w-[62px] flex-shrink-0 overflow-hidden rounded-md border-2">
          <MaintenanceOverlay underMaintenance={maintenance}>
            <img
              src={game.primaryImage?.url}
              alt={game.name}
              className={cn("absolute left-0 top-0 h-full w-full object-fill")}
            />
          </MaintenanceOverlay>
        </div>

        <div className="flex-grow items-start overflow-x-hidden">
          <h3 className="text-content-base overflow-x-hidden text-ellipsis whitespace-nowrap text-left text-base font-bold">
            {game.name}
          </h3>
          <p className="text-content-secondary h-4 overflow-x-hidden overflow-y-hidden text-ellipsis whitespace-nowrap text-left text-xs">
            {providerName}
          </p>
        </div>
        <ButtonIcon
          className="ml-auto !h-8 !w-8 flex-shrink-0"
          disabled={maintenance}
          variant="ghost"
          icon={<MdArrowForward className="scale-[0.8]" />}
        />
      </span>
    );
  }

  // default variant === "thumbnail"
  return (
    <AspectRatio ratio={1}>
      <button
        className="bg-darkBrand-700 border-slateBrand-950 relative aspect-square h-full w-full overflow-hidden rounded-md border-2"
        onClick={handleOnClick}
      >
        <MaintenanceOverlay
          underMaintenance={maintenance}
          size={maintenanceSize}
        >
          <ImageWithFallback
            src={game.primaryImage?.url}
            alt={game.name}
          />
        </MaintenanceOverlay>
      </button>
    </AspectRatio>
  );
};

export default GameEntry;
