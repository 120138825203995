import { publicApi } from "@api";
import { BrandLogo } from "@components";
import { Paths } from "@constants";
import { useServerInfo } from "@hooks";
import { getThemeAsset } from "@utils";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const MaintenancePage = () => {
  const { t } = useTranslation();
  const serverInfo = useServerInfo();

  const { data: serverConfig, isLoading } = publicApi.useGetServerConfigQuery(
    {
      env: import.meta.env.VITE_DEPLOY_ENV,
    },
    {
      skip: import.meta.env.VITE_DEPLOY_ENV === "local",
    },
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (
      (!isLoading && serverConfig && !serverConfig.underMaintenance) ||
      (!serverConfig && import.meta.env.VITE_MAINTENANCE === "false")
    ) {
      navigate(Paths.PUBLIC.ONBOARD_LOGIN, {
        replace: true,
      });
    }
  }, [isLoading, navigate, serverConfig]);

  const handleOpenContactLink = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      if (!serverInfo?.contactLink) return;
      window.open(serverInfo?.contactLink, "_blank");
    },
    [serverInfo?.contactLink],
  );

  return (
    <div className="flex h-screen w-full flex-col items-center px-5 py-8">
      <BrandLogo className="mx-auto" />

      <div className="flex flex-1 flex-col items-center justify-center gap-2">
        <p className="text-[30px] font-bold leading-9">{t("we will be back")}</p>
        <div className="text-center">
          <p className="text-content-secondary text-sm font-normal leading-5">
            {t("the site is being carefully improved")}
          </p>
          <p className="text-content-secondary text-sm font-normal leading-5">{t("thank you for your patience")}</p>
        </div>
        <img
          src={getThemeAsset("misc/maintenance.png")}
          alt="maintenance"
          className="mt-4"
        />
      </div>

      <div className="flex w-full items-center justify-center gap-3">
        <span className="text-content-secondary text-xs font-normal leading-4">{t("contact cs")}</span>
        <div className="flex items-center justify-center gap-2">
          <img
            src="/images/line.png"
            alt="line"
            className="h-6 cursor-pointer hover:opacity-90"
            onClick={handleOpenContactLink}
          />
        </div>
      </div>
    </div>
  );
};

export default MaintenancePage;
