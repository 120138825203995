import { MaintenanceOverlay } from "@components";
import { cn, FundingMethodModel, MaskedImg, PropsWithClassName } from "kz-ui-sdk";
import { AspectRatio } from "react-aspect-ratio";

interface DepositMethodOptionProps extends PropsWithClassName {
  method: FundingMethodModel;
  onClick?: (method: FundingMethodModel) => void;
  labelClassName?: string;
  btnClassName?: string;
  maskedImgClassName?: string;
}

const DepositMethodOption = ({
  method,
  onClick,
  className,
  labelClassName,
  btnClassName,
  maskedImgClassName,
}: DepositMethodOptionProps) => {
  return (
    <AspectRatio
      key={method.key}
      ratio="200/76"
      className={cn(className)}
    >
      <button
        className={cn(
          `relative flex h-full w-full flex-shrink-0 items-center justify-center shadow-[0px_8px_16px_0px_rgba(0,0,0,0.25)]`,
          {
            "bg-white": !method.banner?.url,
          },
          btnClassName,
        )}
        onClick={() => onClick?.(method)}
      >
        <MaintenanceOverlay
          underMaintenance={method.disabled}
          text={"Temporary Unavailable"}
          className="rounded-lg"
        >
          {method.banner?.url && (
            <MaskedImg
              src={method.banner?.url}
              alt={method.key}
              className={cn(
                "!bg-content-base absolute inset-0 h-full w-full rounded-xl object-cover",
                maskedImgClassName,
              )}
              imgClassName="object-cover h-full w-full"
            />
          )}
          {!method.banner?.url && (
            <span
              className={cn(
                "text-content-secondary line-clamp-2 px-2 py-1.5 text-2xl font-semibold leading-[2rem]",
                labelClassName,
              )}
            >
              {method.name}
            </span>
          )}
        </MaintenanceOverlay>
      </button>
    </AspectRatio>
  );
};

export default DepositMethodOption;
