import { useScrollPositionDetector } from "@components/layouts/ScrollBox/hooks";
import { GameModel, cn } from "kz-ui-sdk";
import { useLayoutEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { MdHistory } from "react-icons/md";
import { useGameGateway } from "../../hooks";
import GameEntry from "../GameEntry";
// @ts-ignore
import useScrollOnDrag from "react-scroll-ondrag";

interface RecentGamesProps {
  games: GameModel[];
  shadowClassName?: string;
}

export function RecentGames({ games, shadowClassName }: RecentGamesProps) {
  const { t } = useTranslation();

  const { open: openGame, providerMap } = useGameGateway();

  //#region scroll controller
  const refListContainer = useRef(null);
  const { events: scrollOnDragEvents } = useScrollOnDrag(refListContainer);
  const { checkPosition, isScrollbarAtRight, isScrollbarAtLeft } = useScrollPositionDetector(refListContainer, {
    direction: "horizontal",
  });

  useLayoutEffect(() => {
    checkPosition();
  }, [checkPosition, games?.length]);
  //#endregion

  return (
    !!games.length && (
      <div className="-ml-3 h-fit w-full">
        <div className="ml-3 flex flex-row gap-0.5 pb-1 pt-2 text-xs">
          <MdHistory
            className="text-content-secondary"
            size={14}
          />
          <span className="text-content-base">{t("last played")}</span>
        </div>
        {/* position absolute to avoid horizontal scroll page */}
        <div className="relative h-[68px] w-full">
          <div
            ref={refListContainer}
            {...scrollOnDragEvents}
            onScroll={checkPosition}
            className={`scrollbar-none absolute -left-2 top-0 flex h-[68px] w-[calc(100%+40px)] flex-row gap-x-1.5 overflow-x-auto px-5`}
          >
            {games.map((g, index) => {
              const key = [`recent-${index}`, g.providerRef, g.gameProviderId].filter(Boolean).join("-");
              return (
                <div
                  className={`h-[68px] w-[68px] min-w-[68px]`}
                  key={key}
                >
                  <GameEntry
                    variant={"thumbnail"}
                    game={g}
                    onOpen={openGame}
                    sourceRef={providerMap[g.gameProviderId]?.sourceRef}
                    maintenanceSize="md"
                    maintenance={g.isMaintenance || providerMap[g.gameProviderId]?.isMaintenance}
                  />
                </div>
              );
            })}
          </div>
          {/* <div className="pointer-events-none absolute -right-8 top-0 h-[68px] w-[48px] [background:linear-gradient(270deg,#0C0629_0%,rgba(21,18,64,0.01)_100%)]"></div> */}
          {!isScrollbarAtLeft && (
            <div
              className={cn(
                "pointer-events-none absolute -left-3 top-0 h-full w-12 animate-fade-in bg-gradient-to-r from-darkBrand-900 to-transparent",
                shadowClassName,
              )}
            />
          )}
          {!isScrollbarAtRight && (
            <div
              className={cn(
                "pointer-events-none absolute -right-8 top-0 h-full w-12 animate-fade-in bg-gradient-to-l from-darkBrand-900 to-transparent",
                shadowClassName,
              )}
            />
          )}
        </div>
        <div className="my-[8px] ml-3 h-[1px] w-full bg-border-subtle"></div>
      </div>
    )
  );
}
