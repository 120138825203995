import "./SlothackIcon.css";

const SlothackIcon = () => {
  return (
    <div className="h-[48px] overflow-hidden">
      <div className="relative flex flex-col items-center align-middle">
        <img
          src="/images/slots-hack-ai/ai-face.png"
          alt="daily reward"
          className="slothack-icon__face absolute top-[1px] z-[1] h-[50px] object-contain"
        />
        <img
          src="/images/slots-hack-ai/ai-eye.png"
          alt="daily reward"
          className="slothack-icon__eye absolute left-[10.5px] top-[24px] z-[1] h-[20px] w-[40px] object-contain"
        />

        <img
          src="/images/slots-hack-ai/ai-shine.png"
          alt="daily reward"
          className="slothack-icon__connect absolute top-[-2px] -z-0 h-[60px] w-[58px] object-contain"
        />
      </div>
    </div>
  );
};

export default SlothackIcon;
