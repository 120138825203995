import { publicApi } from "@api";
import { FormTitle } from "@components";
import { deployEnvConfig } from "@configs";
import { usePreference } from "@hooks";
import { resolveRegisterRequestError } from "@views/LoginPage/utils";
import { Button, CodeField, delay, standardizePhoneNumber, useDateAvailCheck, useHandleApiResponse } from "kz-ui-sdk";
import moment from "moment";
import { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

interface FormOTPProps extends PropsWithChildren {
  phone: string;
  onComplete: (code: string) => void;
  OTPValue: string;
  errorMessage: string;
  loading?: boolean;
}

const DEFAULT_OTP_LENGTH = 6;

const FormOTP = ({ errorMessage: errMsg, OTPValue, phone, onComplete, loading }: FormOTPProps) => {
  const [timeCount, setTimeCount] = useState<number | undefined>();
  const refTimer = useRef<NodeJS.Timeout>();
  const { serverInfo } = usePreference();

  const [resendOTP, { isLoading: isResendingOTP }] = publicApi.useCreateRegisterMutation();
  const [submitRegister, { isLoading: isSubmitRegister }] = publicApi.useSubmitRegisterMutation();

  const { handleApiResponse } = useHandleApiResponse({ toast });
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  useEffect(() => {
    setErrorMessage(t(errMsg));
  }, [errMsg, t]);

  const {
    check: checkRequestOTP,
    dueAt: nextOTPAvailDate,
    updateDueAt: updateOTPNextAvailDate,
    key: nextOTPKey,
  } = useDateAvailCheck(standardizePhoneNumber(phone, deployEnvConfig.country.phoneCode));

  const handleOnOTPComplete = useCallback(
    async (code: string) => {
      onComplete(code);
    },
    [onComplete],
  );

  const startTimer = () => {
    // check if timer is already running
    if (refTimer.current) {
      clearInterval(refTimer.current);
    }
    refTimer.current = setInterval(() => {
      setTimeCount((prevCount) => {
        if (prevCount === undefined) return prevCount;
        if (prevCount > 0) {
          return prevCount - 1;
        } else {
          clearInterval(refTimer.current);
          return 0;
        }
      });
    }, 1000);
  };

  useEffect(() => {
    startTimer();
    setTimeout(() => {
      if (localStorage.getItem(nextOTPKey)) {
        updateOTPNextAvailDate(moment(localStorage.getItem(nextOTPKey)));
      }
    }, 1000);
    return () => {
      refTimer.current && clearInterval(refTimer.current);
    };
  }, [checkRequestOTP, nextOTPKey, phone, updateOTPNextAvailDate]);

  useEffect(() => {
    if (nextOTPAvailDate) {
      setTimeCount(Math.max(nextOTPAvailDate.diff(Date.now(), "seconds"), 0));
    }
  }, [nextOTPAvailDate]);

  const formatTimeout = (timeout: number) => {
    const minutes = Math.floor(timeout / 60);
    const seconds = timeout % 60;
    return `${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  const handleResendOTP = async () => {
    if (!checkRequestOTP()) {
      toast.error(`You can request OTP again in ${formatTimeout(timeCount!)}`);
      return;
    }
    const response = await resendOTP({
      phone: standardizePhoneNumber(phone, deployEnvConfig.country.phoneCode),
      accountId: deployEnvConfig.country.accountId,
    });
    if (response.error) {
      handleApiResponse(response, {
        toastError: false,
        onError: (error) => {
          const message = resolveRegisterRequestError(error, t);
          if (message) {
            toast.error(message);
          }
        },
      });
    } else {
      handleApiResponse(response, {
        successMessage: t("OTP sent to your phone!"),
      });
      updateOTPNextAvailDate(response.data.nextRequestAt);
      delay().then(() => {
        startTimer();
      });
    }
  };

  const isFormLoading = useMemo(() => {
    return isResendingOTP || isSubmitRegister || loading;
  }, [isResendingOTP, isSubmitRegister, loading]);

  return (
    <div className="flex w-full flex-col items-center px-5">
      <FormTitle
        phone={phone}
        subTitle={t("Enter the OTP sent to your phone")}
      />
      <div className="mt-6 px-4">
        <CodeField
          length={serverInfo?.onboard?.otpLength ?? DEFAULT_OTP_LENGTH}
          type={"number"}
          shouldAutoFocus
          value={OTPValue}
          onComplete={handleOnOTPComplete}
          error={!!errorMessage}
          helperText={t(errorMessage ?? "")}
          helperTextClasses={{
            className: "!text-center",
          }}
          disabled={isFormLoading}
          variant="ghost"
          className="flex flex-col items-center"
        />

        <div className="mt-6 w-full">
          <Button
            id="btn-next"
            variant="primary"
            size="lg"
            onClick={() => handleOnOTPComplete(OTPValue)}
            disabled={OTPValue == ""}
            loading={isFormLoading}
          >
            {t("Next")}
          </Button>
        </div>

        <div className="mt-6">
          <div className="flex flex-col items-center">
            <p>
              {!!timeCount && (
                <div className="text-content-secondary mb-2 flex flex-col items-center gap-y-0.5 text-xs leading-4">
                  <span>{t("Send code again in")}</span>
                  <span>{formatTimeout(timeCount)}</span>
                </div>
              )}
            </p>
            <Button
              size="sm"
              className="!w-fit px-3"
              variant="secondary"
              loading={isFormLoading}
              onClick={handleResendOTP}
              disabled={timeCount === undefined || timeCount > 0}
            >
              {t("Resend code")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormOTP;
