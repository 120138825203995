import { PropsWithClassName, cn, delay } from "kz-ui-sdk";
import React, { HTMLAttributes, PropsWithChildren } from "react";

interface MenuButtonProps extends PropsWithChildren, PropsWithClassName, HTMLAttributes<HTMLButtonElement> {
  icon?: React.ReactNode;
  rightSection?: React.ReactNode;
}

const MenuButton = ({ className, onClick, icon, children, rightSection, ...btnProps }: MenuButtonProps) => {
  return (
    <button
      className={cn(
        "flex h-12 w-full items-center gap-x-2 rounded-md border-t border-white/10 bg-homeBtn pr-2 transition-opacity duration-200 active:opacity-80",
        className,
      )}
      onClick={(e) => {
        delay(200).then(() => {
          onClick?.(e);
        });
      }}
      {...btnProps}
    >
      <span className="h-[50px] w-[60px]">{icon}</span>
      <span className="mr-auto text-base font-bold leading-7 text-content-base">{children}</span>
      {rightSection}
    </button>
  );
};

export default MenuButton;
