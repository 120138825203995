import { siteApi } from "@api";
import { BrandBanner } from "@components";
import { APP_INSTALLER_HEIGHT, HEADER_HEIGHT, Paths, QUERY_ALL } from "@constants";
import { useTypedSelector } from "@hooks";
import { GameListRef } from "@views/HomePage/components/GameList/GameList.tsx";
import { GameCategoryModel, GameProviderModel, cn, useStickyDetection } from "kz-ui-sdk";
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import { useTranslation } from "react-i18next";
import { MdSearch } from "react-icons/md";
import { matchPath, useLocation } from "react-router-dom";
import { GameCategoryList, GameList, GameProviderList, GameSearchModal, RecentGames } from "./components";
import { CATEGORY_POPULAR_SLUG, RECENT_GAME_QUERY_PARAMS } from "./contants";
import { standardizeGameStats } from "./utils";

const CATEGORY_CONTAINER_HEIGHT = 65;
const BANNER_HEIGHT = 100;
const BANNER_PADDING = 34;
const SCROLL_DOWN_OFFSET = 20;

const HomePage = () => {
  const location = useLocation();
  const { isSticky: isCategoryBarStuck, ref: refStickyCategoryBar } = useStickyDetection();
  const isDisplayAppInstaller = useTypedSelector((state) => state.preferences.displayAppInstaller);
  const refGameList = useRef<GameListRef>(null);
  const [filterByProvider, setFilterByProvider] = useState<GameProviderModel>();
  const [filterByCategory, setFilterByCategory] = useState<GameCategoryModel>();
  const [isDisplaySearchModal, setIsDisplaySearchModal] = useState(false);
  const { t } = useTranslation();

  const refContentContainer = useBottomScrollListener<HTMLDivElement>(
    () => {
      refGameList.current?.fetchNextPage();
    },
    {
      offset: SCROLL_DOWN_OFFSET,
    },
  );

  const { data } = siteApi.useGetGameRecentListQuery(
    {
      query: RECENT_GAME_QUERY_PARAMS,
    },
    {
      skip: filterByCategory?.slug !== CATEGORY_POPULAR_SLUG,
    },
  );

  const recentGames = useMemo(() => data?.entries ?? [], [data?.entries]);

  const { data: providersResponse } = siteApi.useGetGameProviderListQuery({
    query: QUERY_ALL,
  });
  const { data: categoriesResponse, isFetching: isFetchingGameCategory } = siteApi.useGetGameCategoryListQuery({
    query: QUERY_ALL,
  });
  const { data: providersStatsResponse, isFetching: isFetchingProviderStats } = siteApi.useGetGameProviderStatsQuery();

  const defaultCategory = useMemo(() => {
    if (!isFetchingGameCategory && !isFetchingProviderStats) {
      // Find the popular category, if not found, use recent category
      const popularCategory = categoriesResponse?.entries.find((category) => category.slug === CATEGORY_POPULAR_SLUG);
      return popularCategory ?? categoriesResponse?.entries[0];
    }
  }, [categoriesResponse, isFetchingGameCategory, isFetchingProviderStats]);

  const setDefaultFilterProvider = useCallback(
    (category?: GameCategoryModel) => {
      if (!category) return;
      // If category is popular or recent, set provider to null
      if (CATEGORY_POPULAR_SLUG.includes(category?.slug)) {
        setFilterByProvider(undefined);
      } else {
        // If category is not popular or recent, set provider to default provider
        if (providersStatsResponse) {
          const standardizedStats = standardizeGameStats(providersStatsResponse);
          const defaultProvider = providersResponse?.entries
            .filter((provider) => !!standardizedStats?.[provider.id]?.[category.id])
            .find((provider) => !provider.isMaintenance);
          setFilterByProvider(defaultProvider);
        }
      }
    },
    [providersResponse?.entries, providersStatsResponse],
  );

  // Set default category if no category is selected
  useEffect(() => {
    if (!filterByCategory && defaultCategory) {
      setFilterByCategory(defaultCategory);
      setDefaultFilterProvider(defaultCategory);
    }
  }, [defaultCategory, filterByCategory, setDefaultFilterProvider]);

  // Remove padding top & add no-scrollbar class to main element
  useLayoutEffect(() => {
    const contentWrapper = document.getElementsByTagName("main")[0] as HTMLDivElement;
    const toggleClassNames = ["!py-0", "no-scrollbar", "overflow-x-hidden"];
    if (matchPath(Paths.PRIVATE.HOME, location.pathname) && contentWrapper) {
      contentWrapper.classList.add(...toggleClassNames);
    }

    return () => {
      if (contentWrapper) {
        contentWrapper.classList.remove(...toggleClassNames);
      }
    };
  }, [location.pathname, isDisplayAppInstaller]);

  const handleOnSelectProvider = useCallback(
    (provider?: GameProviderModel) => {
      setFilterByProvider(provider);
      // reset scroll bar position
      if (isCategoryBarStuck) {
        refContentContainer.current?.scrollTo(0, BANNER_HEIGHT + BANNER_PADDING);
      }
    },
    [isCategoryBarStuck, refContentContainer],
  );

  const handleOnSelectCategory = useCallback(
    (category?: GameCategoryModel) => {
      setFilterByCategory(category);
      setDefaultFilterProvider(category);
    },
    [setDefaultFilterProvider],
  );

  const gameProviderSelectorKey = useMemo(() => {
    if (filterByCategory?.slug && !CATEGORY_POPULAR_SLUG.includes(filterByCategory.slug)) {
      return "display-provider-list";
    }
    return "hide-provider-list";
  }, [filterByCategory?.slug]);

  const isDisplayProviders = useMemo(() => {
    return !!filterByCategory?.slug && !CATEGORY_POPULAR_SLUG.includes(filterByCategory?.slug);
  }, [filterByCategory?.slug]);

  const { topOffsetProviderList, maxHeightProviderList } = useMemo(() => {
    const WINDOW_HEIGHT = window.innerHeight;
    const INSTALLER_HEIGHT = isDisplayAppInstaller ? APP_INSTALLER_HEIGHT : 0;
    return {
      topOffsetProviderList: CATEGORY_CONTAINER_HEIGHT + INSTALLER_HEIGHT + HEADER_HEIGHT,
      maxHeightProviderList: WINDOW_HEIGHT - CATEGORY_CONTAINER_HEIGHT - HEADER_HEIGHT - INSTALLER_HEIGHT,
      minHeightGameList: WINDOW_HEIGHT - CATEGORY_CONTAINER_HEIGHT - HEADER_HEIGHT - INSTALLER_HEIGHT,
    };
  }, [isDisplayAppInstaller]);

  return (
    <div
      className="xs:scrollbar-primary relative -mx-5 overflow-y-auto px-5"
      ref={refContentContainer}
    >
      <BrandBanner className="mb-[5px] mt-2.5" />

      {/*CATEGORY & SEARCH*/}
      <div
        className={cn(
          "sticky top-[-1px] z-10 -mx-5 mb-1 flex gap-x-1.5 py-1 pr-5 transition-[background,padding-top]",
          {
            "bg-darkBrand-900 pt-[5px]": isCategoryBarStuck,
          },
        )}
        ref={refStickyCategoryBar}
        style={{
          height: CATEGORY_CONTAINER_HEIGHT,
          position: "-webkit-sticky",
        }}
      >
        {/*CATEGORY*/}
        <GameCategoryList
          onSelect={handleOnSelectCategory}
          shadowClassName={cn("", {
            "!from-darkBrand-900": isCategoryBarStuck,
          })}
        />
        {/*SEARCH*/}
        <span>
          <button
            onClick={() => setIsDisplaySearchModal(true)}
            className="h-[56px] w-[64px] rounded-md bg-grdSecondary"
          >
            <div className={"flex flex-col items-center justify-between gap-0.5"}>
              <MdSearch size={24} />
              <span className="text-xs font-semibold text-content-base">{t("Search")}</span>
            </div>
          </button>
        </span>
      </div>

      {!!defaultCategory && (
        <GameSearchModal
          preloadCategory={defaultCategory}
          open={isDisplaySearchModal}
          onClose={() => setIsDisplaySearchModal(false)}
        />
      )}

      {/*RECENT GAMES*/}
      {filterByCategory?.slug === CATEGORY_POPULAR_SLUG && <RecentGames games={recentGames} />}

      {/*GAME LIST*/}
      <div className={cn("-mx-5 flex flex-grow gap-x-2")}>
        {/*PROVIDER LIST*/}
        <div
          className={cn("max-w-[85px] flex-shrink-0 basis-[85px] transition-[opacity,max-width,basis]", {
            "mr-3 !max-w-[0] basis-[0px] overflow-x-hidden opacity-0": !isDisplayProviders,
          })}
          style={{
            maxHeight: maxHeightProviderList,
          }}
        >
          <GameProviderList
            onSelect={handleOnSelectProvider}
            selected={filterByProvider}
            categoryId={filterByCategory?.id}
            key={gameProviderSelectorKey}
            className={cn("xs:scrollbar-primary w-[85px] pb-32 [direction:rtl]", {
              "fixed overflow-y-auto": isCategoryBarStuck && isDisplayProviders,
            })}
            style={{
              top: topOffsetProviderList,
              maxHeight: maxHeightProviderList,
            }}
          />
        </div>

        {/*GAME GRID*/}
        <GameList
          ref={refGameList}
          filters={{
            category: filterByCategory,
            provider: filterByProvider,
          }}
          className={cn("flex-grow overflow-y-auto pb-32 pr-2", {
            "pr-5": !isDisplayProviders,
          })}
          style={{
            minHeight: maxHeightProviderList,
          }}
        />
      </div>
    </div>
  );
};

export default HomePage;
