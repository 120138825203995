import { randomInt } from "@utils";
import { cn, delay, PropsWithClassName } from "kz-ui-sdk";
import { forwardRef, useImperativeHandle, useRef } from "react";
import "./FlyingCoins.css";

interface FlyingCoinsProps extends PropsWithClassName {
  targetId: string;
  coinImgSrc: string;
  count: number;
  sourceCoinSize: number;
  targetCoinSize?: number;
  randomHorizontalRange?: number;
  randomVerticalRange?: number;
  randomSizeRange?: number;
  delayIn?: number;
  overlay?: boolean; // A layer that covers the source DOM element
  duration?: number;
  removeIn?: number;
}

export interface FlyingCoinsRef {
  start: () => void;
}

const FlyingCoins = forwardRef<FlyingCoinsRef, FlyingCoinsProps>(
  (
    {
      className,
      sourceCoinSize,
      randomHorizontalRange,
      randomVerticalRange,
      randomSizeRange,
      count,
      targetId,
      coinImgSrc,
      delayIn = 0,
      overlay = false,
      duration = 2,
      removeIn = 4.4,
      targetCoinSize,
    },
    ref,
  ) => {
    const refSource = useRef<HTMLDivElement>(null);

    const startFlyingCoins = () => {
      const sourcePosition = refSource.current?.getBoundingClientRect();
      const targetPosition = document.getElementById(targetId)?.getBoundingClientRect();
      if (!sourcePosition || !targetPosition) return;

      // add an overlay to the source element
      const parentDOM = overlay ? document.createElement("div") : document.body;
      if (overlay) {
        parentDOM.style.position = "fixed";
        parentDOM.style.zIndex = "9999";
        parentDOM.style.top = "0px";
        parentDOM.style.left = "0px";
        parentDOM.style.width = "100%";
        parentDOM.style.height = sourcePosition.y + "px";
        parentDOM.style.overflow = "hidden";

        document.body.appendChild(parentDOM);
        setTimeout(() => {
          document.body.removeChild(parentDOM);
        }, removeIn * 1000);
      }

      // generate coins
      for (let i = 0; i < count; i++) {
        const coin = document.createElement("img");

        const coinPos = {
          x: sourcePosition.x + randomInt(0, randomHorizontalRange ?? 0),
          y: sourcePosition.y + randomInt(0, randomVerticalRange ?? 0),
        };

        // set random size
        const coinSize = sourceCoinSize + randomInt(-(randomSizeRange ?? 0), randomSizeRange ?? 0);

        coin.src = coinImgSrc;
        coin.style.width = `${coinSize}px`;
        coin.style.height = `${coinSize}px`;
        coin.style.position = overlay ? "absolute" : "fixed";
        coin.style.zIndex = String(9998 - i);
        coin.style.left = `${coinPos.x}px`;
        coin.style.top = `${coinPos.y}px`;
        coin.style.willChange = "transform";
        coin.style.transform = `scale(${randomInt(7, 10) / 10})`;
        coin.style.animationTimingFunction = "ease-in-out";

        // Set custom properties for CSS animations
        coin.style.setProperty("--source-x", `${coinPos.x}px`);
        coin.style.setProperty("--source-y", `${coinPos.y}px`);
        coin.style.setProperty("--source-w", `${coinSize}px`);
        coin.style.setProperty("--source-h", `${coinSize}px`);
        coin.style.setProperty("--target-w", `${targetCoinSize ?? Math.round(coinSize * 0.7)}px`);
        coin.style.setProperty("--target-h", `${targetCoinSize ?? Math.round(coinSize * 0.7)}px`);

        // add the coin to the body
        parentDOM.appendChild(coin);

        // fly to the target position
        setTimeout(
          () => {
            coin.style.setProperty("--target-x", `${targetPosition.x}px`);
            coin.style.setProperty("--target-y", `${targetPosition.y}px`);
            // Apply CSS animation
            coin.style.animation = `flyToTarget ${duration}s forwards`;
          },
          i * randomInt(10, 30) + 50,
        );
      }
    };

    useImperativeHandle(ref, () => ({
      start: () => {
        delay(delayIn * 1000).then(() => {
          startFlyingCoins();
        });
      },
    }));

    return (
      <div
        className={cn(className)}
        ref={refSource}
      ></div>
    );
  },
);

FlyingCoins.displayName = "FlyingCoins";

export default FlyingCoins;
