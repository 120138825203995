import { BrandThemeConfigs } from "../../../theme.config.js";

/**
 * Get the theme color configuration based on the brand key
 */
export const getThemeColorConfig = (brandKey?: string) => {
  // brandKey is optional, if not provided, use the VITE_BRAND_KEY from the environment
  brandKey = brandKey || import.meta.env.VITE_BRAND_KEY;
  // @ts-ignore
  return BrandThemeConfigs[brandKey] || BrandThemeConfigs.default;
};

/**
 * Get the theme asset path based on the theme color configuration
 * Asset path should be relative to the theme folder
 * Examples
 * - Path "/images/logo.png" returns "/images/gold/logo.png"
 * - Path "/logo.png" returns "/images/gold/logo.png"
 * @param assetPath
 */
export const getThemeAsset = (assetPath: string) => {
  const themeConfig = getThemeColorConfig();
  // standardize the asset path
  // remove leading slash
  // remove /images/ prefix if exists
  assetPath = assetPath.startsWith("/") ? assetPath.slice(1) : assetPath;
  assetPath = assetPath.startsWith("images/") ? assetPath.slice(7) : assetPath;
  // add prefix to the asset path
  return `/images/themes/${themeConfig.name}/${assetPath}`;
};
