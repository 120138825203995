import { BackButton, TabNavigation, TabNavigationOption, TabPanelContainer } from "@components";
import { Paths } from "@constants";
import { PageTitle } from "kz-ui-sdk";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { TabPanelInstructions, TabPanelOverview, TabPanelRecords } from "./components";
import { ReferralTabEnum } from "./types";

interface ReferralPageProps {}

const TAB_OPTIONS: TabNavigationOption[] = [
  {
    label: "tab overview",
    value: ReferralTabEnum.OVERVIEW,
  },
  {
    label: "tab records",
    value: ReferralTabEnum.RECORDS,
  },
  {
    label: "tab how to earn",
    value: ReferralTabEnum.INSTRUCTIONS,
  },
];

const ReferralPage = ({}: ReferralPageProps) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentTab, setCurrentTab] = useState<TabNavigationOption>();
  const { i18n } = useTranslation();

  useEffect(() => {
    const tabEnum = searchParams.get("tab") as ReferralTabEnum;
    if (tabEnum) {
      // Set tab from URL
      setCurrentTab(TAB_OPTIONS.find((option) => option.value === tabEnum) || TAB_OPTIONS[0]);
    } else {
      // Default tab
      setCurrentTab(TAB_OPTIONS[0]);
    }
  }, [searchParams]);

  const handleOnChangeTab = useCallback(
    (tab: ReferralTabEnum | TabNavigationOption) => {
      let tabEnum: string;
      if (typeof tab === "string") {
        tabEnum = tab;
      } else {
        tabEnum = tab.value;
      }
      // Update URL
      setSearchParams({ tab: tabEnum });
    },
    [setSearchParams],
  );

  const tabPanelOptions = useMemo(() => {
    return [
      {
        ...TAB_OPTIONS[0],
        panel: <TabPanelOverview />,
      },
      {
        ...TAB_OPTIONS[1],
        panel: <TabPanelRecords changeTab={handleOnChangeTab} />,
      },
      {
        ...TAB_OPTIONS[2],
        panel: <TabPanelInstructions />,
      },
    ];
  }, [handleOnChangeTab]);

  return (
    <>
      <PageTitle
        label={t("refer friend")}
        variant="gradient-purple"
        backButton={
          <BackButton
            replace
            to={Paths.PRIVATE.PRIZES}
          />
        }
        classes={{
          "label&": "uppercase whitespace-nowrap",
          "labelOverlay&": "uppercase whitespace-nowrap",
        }}
      />

      {/*TAB*/}
      <div className="-mx-5">
        {!!currentTab && (
          <TabNavigation
            options={TAB_OPTIONS}
            currentTab={currentTab}
            onChange={handleOnChangeTab}
            className="px-5"
          />
        )}
      </div>

      {/*TAB CONTAINER*/}
      {!!currentTab && (
        <div className="-mx-5 max-w-md">
          <TabPanelContainer
            currentTab={currentTab}
            options={tabPanelOptions}
            panelClassName="px-5"
          />
        </div>
      )}
    </>
  );
};

ReferralPage.displayName = "ReferralPage";

export default ReferralPage;
