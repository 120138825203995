import { SVGProps } from "react";

interface IconNavProfileProps extends SVGProps<SVGSVGElement> {
  active?: boolean;
}

const IconNavProfile = ({ active, ...props }: IconNavProfileProps) => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="transition-colors"
      {...props}
    >
      <g filter="url(#filter0_i_126_3348)">
        <path
          d="M16.8748 2.66675C13.3815 2.66675 10.5415 5.50675 10.5415 9.00008C10.5415 12.4267 13.2215 15.2001 16.7148 15.3201C16.8215 15.3067 16.9282 15.3067 17.0082 15.3201C17.0348 15.3201 17.0482 15.3201 17.0748 15.3201C17.0882 15.3201 17.0882 15.3201 17.1015 15.3201C20.5148 15.2001 23.1948 12.4267 23.2082 9.00008C23.2082 5.50675 20.3682 2.66675 16.8748 2.66675Z"
          fill="currentColor"
        />
        <path
          d="M23.6483 18.8666C19.9283 16.3866 13.8616 16.3866 10.1149 18.8666C8.42161 19.9999 7.48828 21.5333 7.48828 23.1733C7.48828 24.8133 8.42161 26.3333 10.1016 27.4533C11.9683 28.7066 14.4216 29.3333 16.8749 29.3333C19.3283 29.3333 21.7816 28.7066 23.6483 27.4533C25.3283 26.3199 26.2616 24.7999 26.2616 23.1466C26.2483 21.5066 25.3283 19.9866 23.6483 18.8666Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_126_3348"
          x="0.875"
          y="0"
          width="32"
          height="32"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood
            floodOpacity="0"
            result="BackgroundImageFix"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset
            dx="1"
            dy="1"
          />
          <feComposite
            in2="hardAlpha"
            operator="arithmetic"
            k2="-1"
            k3="1"
          />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.627451 0 0 0 0 0.811765 0 0 0 0 1 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_126_3348"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default IconNavProfile;
