import { useRouteChange } from "@hooks";
import BigNumber from "bignumber.js";
import { Drawer, delay, useCustomEventsListener } from "kz-ui-sdk";
import { useCallback, useEffect, useState } from "react";
import { SuccessView, WithdrawForm } from "./components";
import { EVENT_CLOSE_WITHDRAW, EVENT_OPEN_WITHDRAW } from "./constants";

const LARGE_AMOUNT = 1000;

export default function WithdrawDrawer() {
  const [isOpen, setIsOpen] = useState(false);
  const [amount, setAmount] = useState<BigNumber>(new BigNumber(0));

  useCustomEventsListener({
    [EVENT_CLOSE_WITHDRAW]: () => setIsOpen(false),
    [EVENT_OPEN_WITHDRAW]: () => setIsOpen(true),
  });

  const [isSuccess, setIsSuccess] = useState(false);

  const setWithdrawAmount = useCallback((amount: BigNumber) => {
    setAmount(amount);
  }, []);

  // Reset success state when drawer is closed
  useEffect(() => {
    if (!isOpen) {
      delay(500).then(() => setIsSuccess(false));
    }
  }, [isOpen]);

  useRouteChange({
    onChange: () => {
      setIsOpen(false);
    },
  });

  return (
    <Drawer
      open={isOpen}
      onClose={() => setIsOpen(false)}
      floatingHandle={isSuccess}
      safeZone={!isSuccess}
      hideHandler={isSuccess}
    >
      {isSuccess ? (
        <SuccessView showMediumWithdraw={amount.lt(LARGE_AMOUNT)} />
      ) : (
        <WithdrawForm
          onSuccess={() => setIsSuccess(true)}
          onUpdateAmount={setWithdrawAmount}
        />
      )}
    </Drawer>
  );
}
