import { PropsWithChildren } from "react";
import { ClaimSection, ShareLinkSection, StatsSection } from "./components";

interface TabPanelOverviewProps extends PropsWithChildren {}

const TabPanelOverview = (props: TabPanelOverviewProps) => {
  return (
    <div className="">
      {/*HERO  */}
      <div className="bg-referFriendTopSection -mx-5 px-5">
        <ClaimSection />
      </div>

      <div className="mb-[-36px] translate-y-[-36px]">
        {/*SHARE LINK*/}
        <ShareLinkSection className="mb-3" />

        {/*STATS*/}
        <StatsSection className="pt-3" />
      </div>
    </div>
  );
};

export default TabPanelOverview;
