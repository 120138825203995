import { IconSpinnerBars } from "@assets";
import { CoinHeartbeat } from "@components";
import { Button, WarningWrapper, cn } from "kz-ui-sdk";
import { PropsWithChildren, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import MenuButton from "../MenuButton";
import "./utils/animations.css";

interface RewardButtonProps extends PropsWithChildren {
  // Display claim now button
  claimable?: boolean;
  // Display locked button
  locked?: boolean;
  lockedIcon?: ReactNode;
  rewardIcon?: ReactNode;
  onClick?: () => void;
  isShine?: boolean;
}

const RewardButton = ({
  claimable = false,
  locked,
  lockedIcon,
  rewardIcon,
  children,
  isShine,
  onClick,
}: RewardButtonProps) => {
  const { t } = useTranslation();
  //
  return (
    <div className="relative">
      <MenuButton
        icon={rewardIcon}
        className={cn({ "pointer-events-none opacity-50": locked })}
        onClick={onClick}
        rightSection={
          <>
            {locked ? (
              lockedIcon
            ) : (
              <WarningWrapper
                isShown={claimable}
                icon={
                  <CoinHeartbeat
                    className="absolute -right-1 top-[-5px]"
                    imgClassName="h-4 w-4"
                  />
                }
              >
                <Button
                  size="sm"
                  onClick={onClick}
                  classes={{
                    "label&": "!text-white",
                    "root&": "max-w-fit px-[12px] min-w-[58px]",
                  }}
                  loader={<IconSpinnerBars className="text-content-base" />}
                  throttleIn={2000}
                >
                  {t(claimable ? "prizes claim" : "Open")}
                </Button>
              </WarningWrapper>
            )}
          </>
        }
      >
        {children}
      </MenuButton>
      {claimable || isShine ? (
        <div className="pointer-events-none absolute left-0 top-0 z-[6] h-full w-full overflow-hidden rounded-lg">
          <div className="reward-btn__shine--claimable absolute left-0 top-0 h-full w-full bg-[linear-gradient(100deg,rgba(255,255,255,0.01)_21.79%,rgba(255,255,255,0.20)_71.43%,rgba(255,255,255,0.01)_99.09%)] will-change-transform [clip-path:polygon(0%_0%,100%_0%,90%_100%,0%_100%)]"></div>
        </div>
      ) : null}
    </div>
  );
};

export default RewardButton;
