import { closeWithdraw, SuccessHeartbeat } from "@components/utils";
import { deployEnvConfig } from "@configs";
import { getThemeAsset } from "@utils";
import { Button } from "kz-ui-sdk";
import { useTranslation } from "react-i18next";

interface SuccessViewProps {
  showMediumWithdraw?: boolean;
}

export default function SuccessView({ showMediumWithdraw }: SuccessViewProps) {
  const { t } = useTranslation();

  const imgUrl = getSuccessHeaderImg(showMediumWithdraw);

  return (
    <div className="flex h-[400px] flex-col items-center overflow-hidden rounded-t-2xl pb-3 pt-3 text-light1">
      {/*  */}
      <div className="h-[170px] w-full overflow-hidden rounded-t-2xl">
        <div
          className="absolute -top-1 left-0 h-[200px] w-full rounded-t-2xl border-b border-white border-opacity-[0.12] bg-cover bg-center bg-no-repeat"
          style={{
            backgroundImage: imgUrl,
          }}
        ></div>
      </div>

      <div className="bg-dialog w-full">
        <div className="relative mb-auto ml-auto mr-auto mt-auto h-[50px] w-[56px]">
          <SuccessHeartbeat
            className="left-300 absolute -top-[16px]"
            imgClassName="h-[56px] w-[56px]"
          />
        </div>
        <div className="text-content-base mb-2 text-center text-xl font-bold tracking-[-0.72px]">
          {t("withdrawal approved")}
        </div>
        <div className="text-content-secondary text-content-secondary mb-6 text-center text-sm tracking-[-0.54px]">
          {t("money is being sent to you")}
        </div>
        <div className="w-full px-5">
          <Button
            variant="secondary"
            size="md"
            onClick={closeWithdraw}
            className="h-[50px]"
          >
            {t("close")}
          </Button>
        </div>
      </div>
    </div>
  );
}

const getSuccessHeaderImg = (showMediumWithdraw?: boolean) => {
  const imgName = "withdraw-success";
  const imgSize = showMediumWithdraw ? "100" : "1000";
  const country = deployEnvConfig.country.deployCountry;
  const imgFile = `${imgName}-${imgSize}-${country}.png`;
  return `url('${getThemeAsset(`/drawer-headers/${imgFile}`)}')`;
};
