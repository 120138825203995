import { cn } from "kz-ui-sdk";
import { PropsWithChildren, useCallback, useLayoutEffect, useRef } from "react";
import { useScrollPositionDetector } from "./hooks";

interface ScrollableContainerProps extends PropsWithChildren {
  delayScrollCheck?: number;
  direction?: "vertical" | "horizontal";
  classes?: {
    root?: string;
    shadow?: string;
    content?: string;
  };
}

/**
 * A scrollable container with shadows at top and bottom
 */
const ScrollBox = ({
  children,
  delayScrollCheck = 0,
  classes: { root, shadow, content } = {},
  direction = "vertical",
}: ScrollableContainerProps) => {
  const refContainer = useRef<HTMLDivElement>(null);

  const { checkPosition, isScrollbarAtBottom, isScrollbarAtTop } = useScrollPositionDetector(refContainer, {
    direction,
  });

  useLayoutEffect(() => {
    setTimeout(() => {
      checkPosition();
    }, delayScrollCheck);
  }, [checkPosition, delayScrollCheck, children]);

  const handleScroll = useCallback(() => {
    checkPosition();
  }, [checkPosition]);

  return (
    <div className={cn("relative overflow-y-hidden", root)}>
      <div
        className={cn("scrollbar-primary overflow-y-auto", content)}
        ref={refContainer}
        onScroll={handleScroll}
      >
        {children}
      </div>

      {/*TOP SHADOW*/}
      {!isScrollbarAtTop && (
        <div className={cn("shadow-darkBrand-900 absolute left-0 top-0 z-[65] h-0 w-full", shadow)} />
      )}

      {/*BOTTOM SHADOW*/}
      {!isScrollbarAtBottom && (
        <div className={cn("shadow-darkBrand-900 absolute bottom-0 left-0 z-[65] h-0 w-full", shadow)} />
      )}
    </div>
  );
};

export default ScrollBox;
