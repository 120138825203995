import { BalanceBadge, BrandLogo, ContactButton } from "@components";
import { BALANCE_BADGE_TOP_ID, HEADER_HEIGHT, Paths } from "@constants";
import { useServerInfo, useTypedSelector } from "@hooks";
import { PageHeader as KZPageHeader, PropsWithClassName, cn } from "kz-ui-sdk";
import { useMemo } from "react";
import { matchPath, useLocation } from "react-router-dom";

interface PageHeaderProps extends PropsWithClassName {}

const deferredUpdateBalancePaths = [Paths.PRIVATE.CASHBACK, Paths.PRIVATE.REFERRAL];
const skipUpdateBalancePaths = [Paths.PRIVATE.DAILY_REWARD];

export default function PageHeader({ className }: PageHeaderProps) {
  const pathname = useLocation().pathname;
  const { contactLink: preLoginContactLink } = useServerInfo() ?? {};
  const { contactLink: postLoginContactLink } = useTypedSelector((state) => state.auth);

  const { delayUpdate, skipUpdate } = useMemo(() => {
    let delayUpdate: number | undefined;
    let skipUpdate: boolean | undefined;
    // In daily reward page, we need to skip the update of balance badge
    if (skipUpdateBalancePaths.some((path) => matchPath(path, pathname))) {
      skipUpdate = true;
    }
    // In cashback/refer page, we need to delay the update of balance badge
    if (deferredUpdateBalancePaths.some((path) => matchPath(path, pathname))) {
      delayUpdate = 1200;
    }
    return {
      delayUpdate,
      skipUpdate,
    };
  }, [pathname]);

  //#region contact links
  const contactLinks = useMemo(() => {
    return [postLoginContactLink || preLoginContactLink].filter(Boolean) as string[];
  }, [preLoginContactLink, postLoginContactLink]);

  const renderContactLinks = useMemo(() => {
    const links = [];

    for (const link of contactLinks) {
      links.push(
        <ContactButton
          key={link}
          url={link}
        />,
      );
    }

    return links;
  }, [contactLinks]);
  //#endregion

  return (
    <KZPageHeader
      fixed
      className={cn("z-10", className)}
    >
      <div
        className="bg-header mx-auto flex max-w-md flex-row items-center justify-between border-b border-white/10 bg-white/5 px-6 py-3"
        style={{
          height: HEADER_HEIGHT,
        }}
      >
        {/*LOGO*/}
        <div className="h-8 w-auto">
          <BrandLogo
            size="auto"
            navigateTo={Paths.PRIVATE.HOME}
          />
        </div>

        {/*CONTACT LINKS*/}
        <div className="mx-3 ml-auto flex gap-1">{renderContactLinks}</div>

        {/*BALANCE BADGE*/}
        <BalanceBadge
          id={BALANCE_BADGE_TOP_ID}
          delayUpdate={delayUpdate}
          skipAutoUpdate={skipUpdate}
        />
      </div>
    </KZPageHeader>
  );
}
