import { siteApi } from "@api";
import {
  BackButton,
  CashbackIcon,
  DailyChestClaimIcon,
  DeferNavLink,
  ILVIcon,
  ReferralIcon,
  SlothackIcon,
} from "@components";
import { Paths } from "@constants/paths";
import usePromotionStatus from "@hooks/usePromotionStatus";
import { RewardButton } from "./components";

import { AccountVariableContent, AccountVariableKey, PageTitle, Skeleton, cn } from "kz-ui-sdk";
import moment from "moment";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { MdSchedule } from "react-icons/md";

type ExternalLink = {
  title: string;
  icon: string;
  url: string;
};

const PrizesPage = () => {
  const { t } = useTranslation();
  const {
    isFetching: isFetchingPromotionStatus,
    isDailyBonusAvailable,
    isCashbackAvailable,
    isCashbackClaimable,
    isReferralAvailable,
    isReferralClaimable,
    remainingSeconds,
    isDailyBonusClaimable,
  } = usePromotionStatus();
  const { data: accVarResponse, isFetching: isFetchingExternalLinks } = siteApi.useGetAccountVariableQuery({
    query: {
      key: AccountVariableKey.ExternalLinks,
    },
  });

  const externalLinks: ExternalLink[] = useMemo(() => {
    const links = [];
    const extLinkConfig = accVarResponse?.entries?.find((entry) => entry.key === AccountVariableKey.ExternalLinks)
      ?.content as AccountVariableContent[AccountVariableKey.ExternalLinks] | undefined;
    if (!extLinkConfig?.externalLinks) return [];

    const linksConfig = extLinkConfig.externalLinks;

    // External link for VIP
    if (linksConfig.vipVisibility && linksConfig.vip) {
      links.push({
        title: t("VIP"),
        icon: "/images/vip/icon-home-vip.png",
        url: linksConfig.vip,
      });
    }

    // External link for Slots Hack AI
    if (linksConfig.slotsHackVisibility && linksConfig.slotsHack) {
      links.push({
        title: t("Slotshack"),
        icon: "/images/slots-hack-ai/icon-home-slots-hack-ai.png",
        url: linksConfig.slotsHack,
      });
    }
    return links;
  }, [t, accVarResponse]);

  const isFetchingData = useMemo(() => {
    return isFetchingPromotionStatus || isFetchingExternalLinks;
  }, [isFetchingExternalLinks, isFetchingPromotionStatus]);

  return (
    <>
      <PageTitle
        label={t("Prizes")}
        variant="gradient-purple"
        backButton={<BackButton />}
        className="mb-4"
      />

      {!isFetchingData && (
        <div>
          {/*DAILY LOGIN*/}
          {isDailyBonusAvailable && (
            <DeferNavLink
              className={cn("mb-2 block", {
                "pointer-events-none": !isDailyBonusAvailable,
              })}
              to={Paths.PRIVATE.DAILY_REWARD}
            >
              <RewardButton
                claimable={isDailyBonusClaimable}
                rewardIcon={<DailyChestClaimIcon claimable={isDailyBonusClaimable} />}
                locked={!isDailyBonusClaimable}
                lockedIcon={
                  <div className="z-50 flex items-center gap-x-1">
                    <div className="font-feat-tnum text-[12px] font-bold">
                      {moment.utc(remainingSeconds * 1000).format("HH:mm:ss")}
                    </div>
                    <MdSchedule className="h-4 w-4" />
                  </div>
                }
              >
                {t("Daily Login")}
              </RewardButton>
            </DeferNavLink>
          )}

          {/*CASHBACK*/}
          {isCashbackAvailable && (
            <DeferNavLink
              className={cn("mb-2 block", {
                "pointer-events-none": !isCashbackAvailable,
              })}
              to={Paths.PRIVATE.CASHBACK}
            >
              <RewardButton
                claimable={isCashbackClaimable}
                rewardIcon={<CashbackIcon claimable={isCashbackClaimable} />}
              >
                {t("Cashback")}
              </RewardButton>
            </DeferNavLink>
          )}

          {/*REFERRAL*/}
          {isReferralAvailable && (
            <DeferNavLink
              className={cn("mb-2 block")}
              to={Paths.PRIVATE.REFERRAL}
            >
              <RewardButton
                claimable={isReferralClaimable}
                rewardIcon={<ReferralIcon claimable={isReferralClaimable ?? false} />}
              >
                {t("refer friend")}
              </RewardButton>
            </DeferNavLink>
          )}

          {externalLinks.map((link) => (
            <DeferNavLink
              key={link.url}
              className={cn("mb-2 block")}
              to={link.url}
              openNewTab
            >
              <RewardButton
                isShine
                rewardIcon={link.title === "VIP" ? <ILVIcon /> : <SlothackIcon />}
              >
                {link.title}
              </RewardButton>
            </DeferNavLink>
          ))}
        </div>
      )}

      {isFetchingData && (
        <Skeleton
          count={5}
          className="mb-2 !h-[48px] !rounded-lg"
        />
      )}
    </>
  );
};

export default PrizesPage;
