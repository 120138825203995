import "./ILVIcon.css";

const ILVIcon = () => {
  return (
    <div className="h-[48px] overflow-hidden">
      <div className="relative flex flex-col items-center align-middle">
        {/*HEART*/}
        <img
          src="/images/vip/vip-heart.png"
          alt="daily reward"
          className="vip-icon-heart absolute top-0 z-[1] rounded-full object-contain"
        />
        <img
          src="/images/vip/vip-heart-glow.png"
          alt="daily reward"
          className="vip-icon-heart__glow absolute top-0 rounded-full object-contain"
        />

        {/*PLATFORM*/}
        <img
          src="/images/vip/vip-platform.png"
          alt="daily reward"
          className="vip-icon-platform absolute top-3 h-[50px] w-[48px] object-contain"
        />
        <img
          src="/images/vip/vip-shadow.png"
          alt="daily reward"
          className="vip-icon-platform absolute top-3 h-[50px] w-[48px] object-contain"
        />
      </div>
    </div>
  );
};

export default ILVIcon;
