import { siteApi } from "@api";
import { ClaimButton, FlyingCoins, FlyingCoinsRef, PointLabel, PointLabelRef, toast } from "@components";
import { BALANCE_BADGE_TOP_ID } from "@constants";
import { getThemeAsset } from "@utils";
import { ReferralEvent } from "@views/ReferralPage/types";
import BigNumber from "bignumber.js";
import { BN_ZERO, Skeleton, bnOrZero, cn, fAmount, useHandleApiResponse } from "kz-ui-sdk";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

interface ClaimSectionProps {}

type AnimKeyframe = "claimable" | "claiming" | "loading";

const ClaimSection = ({}: ClaimSectionProps) => {
  const { t } = useTranslation();
  const [claimAmount, setClaimAmount] = useState<BigNumber>();
  const [minClaimAmount, setMinClaimAmount] = useState<BigNumber>(BN_ZERO);
  const [animKeyframe, setAnimKeyframe] = useState<AnimKeyframe>("loading");
  const pointLabelRef = useRef<PointLabelRef>(null);
  const { data: infoResponse, isLoading: isLoadingInfo } = siteApi.useGetReferralInfoQuery({});
  const [getAvailableClaim, { isLoading: isLoadingClaimAmount }] = siteApi.useLazyGetReferralAvailableClaimQuery({});
  const [claimReferral, { isLoading: isClaiming }] = siteApi.useClaimReferralMutation();
  const { handleApiResponse } = useHandleApiResponse({
    toast,
  });
  const refFlyingCoins = useRef<FlyingCoinsRef>(null);

  // init balance
  useEffect(() => {
    getAvailableClaim({}).then((res) => {
      if (res.data) {
        setClaimAmount(res.data.availableAmount);
      }
    });
  }, [getAvailableClaim]);

  // init min claim amount
  useEffect(() => {
    if (infoResponse?.referralConfig?.minComAmt) {
      if (!minClaimAmount.eq(infoResponse?.referralConfig?.minComAmt)) {
        setMinClaimAmount(bnOrZero(infoResponse?.referralConfig?.minComAmt));
      }
    }
  }, [infoResponse?.referralConfig?.minComAmt, minClaimAmount]);

  const levelConfig: LevelConfig = useMemo(() => {
    // find the first level that the balance is greater than or equal to
    const balanceBn = bnOrZero(claimAmount);
    for (const level of LEVEL_CONFIGS) {
      if (balanceBn.gte(level.condition)) {
        return level;
      }
    }
    return {
      condition: 0,
      classes: {
        coinPileIn: "",
        coinLayerGroup: "",
        coinLayer1: "",
        coinLayer2: "",
        coinLayer3: "",
        coinLayer4: "",
      },
      flyingCoins: 0,
    };
  }, [claimAmount]);

  const claimable = useMemo(() => {
    return claimAmount?.gt(0) && claimAmount?.gte(minClaimAmount);
  }, [claimAmount, minClaimAmount]);

  useEffect(() => {
    if (claimable) {
      setAnimKeyframe("claimable");
    }
  }, [claimable]);

  const handleOnClaim = async () => {
    // check if balance is 0
    if (claimAmount?.eq(0)) {
      toast.info(t("nothing to claim yet. refer more friends"));
      return;
    }

    // check min claim amount
    if (claimAmount?.lt(minClaimAmount)) {
      toast.info(t("minimum amount to claim. refer more friends", { amount: fAmount(minClaimAmount) }));
      return;
    }

    // Claim referral
    const claimRes = await claimReferral();
    handleApiResponse(claimRes, {
      onSuccess: async () => {
        pointLabelRef.current?.count();
        refFlyingCoins.current?.start();
        setAnimKeyframe("claiming");
        const newBalanceRes = await getAvailableClaim({}).refetch();
        window.dispatchEvent(new Event(ReferralEvent.CLAIMED));
        setClaimAmount(newBalanceRes.data?.availableAmount ?? BN_ZERO);
        setTimeout(() => {
          setAnimKeyframe("claimable");
        }, 2000);
      },
    });
  };

  return (
    <div className="relative flex h-[192px] justify-between pt-3">
      {/*CLAIM AMOUNT*/}
      {!isLoadingClaimAmount && !!claimAmount && !isLoadingInfo && (
        <div className="absolute left-1 top-[42px] z-10 w-full animate-fade-in will-change-transform">
          <PointLabel
            endVal={0}
            startVal={claimAmount.toNumber()}
            type="down"
            className={cn("block text-[30px] font-bold leading-9", {
              "text-brand-200": !claimable && animKeyframe !== "claiming",
              "bg-[linear-gradient(0deg,_#F5C12A_13.89%,_#FAE68D_84.72%)] bg-clip-text text-transparent drop-shadow-[0px_2px_0px_rgba(0,_0,_0,_0.40)]":
                claimable || animKeyframe === "claiming",
            })}
            decimal={2}
            ref={pointLabelRef}
            duration={1.5}
          />
        </div>
      )}

      {/*CLAIM BUTTON*/}
      {/*SKELETON*/}
      {isLoadingClaimAmount && (
        <div className="flex max-w-[160px] flex-1 flex-col pt-4">
          <div className="py-1">
            <Skeleton className="mb-0.5 !h-[10px] !w-20" />
            <Skeleton className="!h-9 !w-40" />
          </div>
          <div className="mt-1 max-w-[160px]">
            <Skeleton className="!h-10" />
          </div>
        </div>
      )}
      {!isLoadingClaimAmount && (
        <div className="flex flex-1 animate-fade-in flex-col pt-4">
          <div className="p-1">
            <span className="block text-[10px] uppercase leading-[10px] text-content-secondary">
              {t("your income")}
            </span>
            <div className="h-9"></div>
          </div>
          <div className="mt-1 max-w-[160px]">
            <ClaimButton
              onClick={handleOnClaim}
              text={t("claim cashback")}
              coinClassName="!duration-[0.5s] ![transition-delay:0s]"
              withCoin={claimable && animKeyframe !== "claiming"}
              withShine={claimable && animKeyframe !== "claiming"}
              withFlyingCoins={false}
              claimable
              loading={isClaiming}
            />
          </div>
        </div>
      )}
      {/*CAT ICON*/}
      <div className="relative h-[180px] flex-shrink-0 basis-[140px]">
        <img
          src={getThemeAsset("/referral/icon-cat.png")}
          className="h-[180px] w-[140px]"
          alt="icon-cat"
        />
        {/*COIN PILE*/}
        <div
          className="absolute left-0 top-0 h-full w-full"
          style={{
            maskImage: "url(/images/referral/coin-pile-mask.svg)",
            WebkitMaskImage: "url(/images/referral/coin-pile-mask.svg)",
            maskRepeat: "no-repeat",
          }}
        >
          <img
            src={getThemeAsset("referral/coin-pile.png")}
            className={cn(
              "absolute left-1/2 top-[85px] h-[90px] w-[120px] -translate-x-[53%] transition-[transform,opacity] duration-[1s] ease-bounce-slight will-change-transform",
              levelConfig.classes.coinPileIn,
            )}
            alt="coin-pile"
          />
        </div>
        {/*COIN LAYERS*/}
        <div
          className={cn(
            "absolute left-0 top-[110px] h-[100px] w-full opacity-0 transition-[opacity,transform] duration-[1s] ease-bounce-slight will-change-transform",
            levelConfig.classes.coinLayerGroup,
          )}
        >
          <img
            src={getThemeAsset("referral/coin-layer-4.png")}
            className={cn(
              "absolute left-1/2 top-[0px] h-[50px] w-[115px] -translate-x-[45%] opacity-0 transition-[opacity,transform] duration-1000 ease-bounce-slight will-change-transform",
              levelConfig.classes.coinLayer4,
            )}
            alt={"coin-layer-4"}
          />
          <img
            src={getThemeAsset("referral/coin-layer-3.png")}
            className={cn(
              "absolute left-1/2 top-[36px] h-[40px] w-[136px] -translate-x-1/2 translate-y-4 opacity-0 transition-[opacity,transform] delay-[0ms,100ms] duration-1000 ease-bounce-slight will-change-transform",
              levelConfig.classes.coinLayer3,
            )}
            alt={"coin-layer-3"}
          />
          <img
            src={getThemeAsset("referral/coin-layer-2.png")}
            className={cn(
              "absolute left-1/2 top-[55px] h-[37px] w-[146px] -translate-x-1/2 translate-y-4 opacity-0 transition-[opacity,transform] delay-[0ms,200ms] duration-1000 ease-bounce-slight will-change-transform",
              levelConfig.classes.coinLayer2,
            )}
            alt={"coin-layer-2"}
          />
          <img
            src={getThemeAsset("referral/coin-layer-1.png")}
            className={cn(
              "absolute left-1/2 top-[72px] h-[33px] w-[168px] -translate-x-1/2 translate-y-4 opacity-0 transition-[opacity,transform] delay-[0ms,300ms] duration-1000 ease-bounce-slight will-change-transform",
              levelConfig.classes.coinLayer1,
            )}
            alt={"coin-layer-1"}
          />
        </div>

        <FlyingCoins
          coinImgSrc={"/images/icon-coin.png"}
          sourceCoinSize={28}
          randomSizeRange={14}
          targetCoinSize={16}
          count={levelConfig.flyingCoins}
          targetId={BALANCE_BADGE_TOP_ID}
          className={cn("absolute left-[30px] top-[86px] h-[35px] w-[80px]")}
          ref={refFlyingCoins}
          randomHorizontalRange={60}
          randomVerticalRange={70}
          delayIn={0.05}
          overlay={true}
          duration={1.5}
          removeIn={4.4}
        />
      </div>
    </div>
  );
};

type LevelConfig = {
  condition: number;
  classes: {
    coinPileIn: string;
    coinLayerGroup: string;
    coinLayer1: string;
    coinLayer2: string;
    coinLayer3: string;
    coinLayer4: string;
  };
  flyingCoins: number;
};

const LEVEL_CONFIGS: LevelConfig[] = [
  {
    condition: 300,
    classes: {
      coinPileIn: "-translate-y-[92%] opacity-100",
      coinLayerGroup: "translate-y-[-70px] opacity-100",
      coinLayer1: "opacity-100 !translate-y-0",
      coinLayer2: "opacity-100 !translate-y-0",
      coinLayer3: "opacity-100 !translate-y-0",
      coinLayer4: "opacity-100",
    },
    flyingCoins: 15,
  },
  {
    condition: 200,
    classes: {
      coinPileIn: "-translate-y-[83%] opacity-100",
      coinLayerGroup: "translate-y-[-55px] opacity-100",
      coinLayer1: "opacity-0",
      coinLayer2: "opacity-100 !translate-y-0",
      coinLayer3: "opacity-100 !translate-y-0",
      coinLayer4: "opacity-100",
    },
    flyingCoins: 12,
  },
  {
    condition: 100,
    classes: {
      coinPileIn: "-translate-y-[60%] opacity-100",
      coinLayerGroup: "opacity-100 translate-y-[-44px]",
      coinLayer1: "opacity-0",
      coinLayer2: "opacity-0",
      coinLayer3: "opacity-100 !translate-y-0",
      coinLayer4: "opacity-100",
    },
    flyingCoins: 9,
  },
  {
    condition: 50,
    classes: {
      coinPileIn: "-translate-y-[40%] opacity-100",
      coinLayerGroup: "opacity-100 translate-y-[-18px]",
      coinLayer1: "opacity-0",
      coinLayer2: "opacity-0",
      coinLayer3: "opacity-0",
      coinLayer4: "opacity-100",
    },
    flyingCoins: 7,
  },
  {
    condition: 0.01,
    classes: {
      coinLayerGroup: "opacity-0",
      coinPileIn: "-translate-y-[25%] opacity-100",
      coinLayer1: "opacity-0",
      coinLayer2: "opacity-0",
      coinLayer3: "opacity-0",
      coinLayer4: "opacity-0",
    },
    flyingCoins: 5,
  },
];

export default ClaimSection;
