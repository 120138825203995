import { IconSpinnerBars } from "@assets";
import { generateFlyingCoins } from "@components/inputs/ClaimButton/utils";
import { BALANCE_BADGE_TOP_ID } from "@constants";
import { Button, cn } from "kz-ui-sdk";
import { ButtonModifier } from "kz-ui-sdk/dist/components/inputs/Button/Button";
import { PropsWithChildren, forwardRef, useCallback, useImperativeHandle, useRef } from "react";
import "./ClaimButton.css";
import "./utils/animations.css";

interface ClaimButtonProps extends PropsWithChildren {
  withCoin?: boolean;
  withShine?: boolean;
  withFlyingCoins?: boolean;
  wrapperClassName?: string;
  coinClassName?: string;
  claimable?: boolean;
  onClick?: () => void;
  text?: string;
  loading?: boolean;
  classes?: {
    text?: string;
  };
  size?: ButtonModifier | undefined;
}

const NUMBER_OF_FLYING_COINS = 5;
const COIN_SIZE = 20;

export interface ClaimButtonRef {
  renderFlyingCoins: () => void;
}

const ClaimButton = forwardRef<ClaimButtonRef, ClaimButtonProps>(
  (
    {
      claimable,
      onClick,
      withCoin,
      withShine,
      coinClassName = "!duration-[1s] [transition-delay:1.5s]",
      withFlyingCoins,
      wrapperClassName,
      text,
      loading,
      classes,
      size = "md",
    },
    ref,
  ) => {
    const coinIconRef = useRef<HTMLDivElement>(null);

    const renderFlyingCoins = useCallback(() => {
      if (!withFlyingCoins) return;
      if (coinIconRef.current) {
        const targetPosition = document.getElementById(BALANCE_BADGE_TOP_ID)?.getBoundingClientRect();
        if (targetPosition) {
          generateFlyingCoins(
            coinIconRef.current,
            targetPosition,
            NUMBER_OF_FLYING_COINS,
            {
              source: {
                width: COIN_SIZE,
                height: COIN_SIZE,
              },
              target: {
                width: COIN_SIZE - 6,
                height: COIN_SIZE - 6,
              },
            },
            {
              durations: {
                bounceDown: 0,
                delay: 0.1,
                skipBounceDown: true,
                withTrail: false,
              },
              positions: {
                fixed: {
                  bounceDown: {
                    x: 0,
                    y: 0,
                  },
                },
              },
            },
          );
        }
      }
    }, [withFlyingCoins]);

    useImperativeHandle(ref, () => ({
      renderFlyingCoins,
    }));

    return (
      <div className={cn("relative h-[40px] w-full", wrapperClassName)}>
        <div
          className={cn("pointer-events-none absolute right-[-6px] top-[-7px] z-[5] opacity-100", {
            [`!opacity-0 transition-opacity ${coinClassName}`]: !withCoin,
          })}
          ref={coinIconRef}
        >
          <div className="claim-btn__coin-ring--claimable absolute left-[-3px] top-[-3px] h-[22px] w-[22px] rounded-full bg-[radial-gradient(circle,_rgba(255,255,255,1)_0%,_rgba(255,255,255,0)_100%)] will-change-transform" />
          <img
            src="/images/icon-coin.png"
            className="claim-btn__coin--claimable h-4 w-4 rounded-full border border-[#0a1341] will-change-transform"
            alt=""
          />
        </div>

        <div
          key={text}
          className={cn(
            "pointer-events-none absolute left-0 top-0 z-0 h-full w-full opacity-0 transition-opacity !duration-[2s]",
            {
              "pointer-events-auto z-[1] opacity-100": claimable,
            },
          )}
        >
          <Button
            size={size}
            loading={loading}
            disabledOnLoading={false}
            variant="primary"
            onClick={onClick}
            classes={{
              "label&": "!text-white",
            }}
            loader={<IconSpinnerBars className="text-content-base" />}
            throttleIn={2000}
          >
            {text}
          </Button>
        </div>

        <div
          className={cn("absolute left-0 top-0 z-0 h-full w-full opacity-0 transition-opacity !duration-[2s]", {
            "z-[1] opacity-100": !claimable,
          })}
        >
          <Button
            size={size}
            loading={loading}
            disabledOnLoading={false}
            variant="secondary"
            className={cn("pointer-events-none animate-fade-in")}
            key={text}
            classes={{
              "label&": cn("!text-content-secondary", classes?.text),
            }}
            loader={<IconSpinnerBars className="text-content-base" />}
          >
            {text}
          </Button>
        </div>

        {withShine && !loading && (
          <div
            className={cn("pointer-events-none absolute left-0 top-0 z-[6] w-full overflow-hidden rounded-lg", {
              "h-[50px]": size === "lg",
              "h-full": size !== "lg",
            })}
          >
            <div className="claim-btn__shine--claimable absolute left-0 top-0 h-full w-[70px] bg-[linear-gradient(100deg,rgba(255,255,255,0.01)_21.79%,rgba(255,255,255,0.20)_71.43%,rgba(255,255,255,0.01)_76.09%)] will-change-transform"></div>
          </div>
        )}
      </div>
    );
  },
);

ClaimButton.displayName = "ClaimButton";

export default ClaimButton;
