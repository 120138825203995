export enum NativeDepositStatus {
  INIT = "INIT",
  PENDING = "PENDING",
  SUCCESS = "SUCCESS",
  TIMEOUT = "TIMEOUT",
}

export enum NativeUploadSlipStep {
  INIT = "INIT",
  UPLOADING = "UPLOADING",
}

export enum NativeUploadSlipStatus {
  INIT = "INIT",
  UPLOADING = "UPLOADING",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

/**
 * Deposit drawer screen
 * NATIVE_DEPOSIT: Native deposit screen to show native deposit information
 * AMOUNT_SELECTION: Amount selection screen to show amount selection form
 * METHOD_SELECTION: Method selection screen to show deposit method selection
 * Example flow: METHOD_SELECTION -> AMOUNT_SELECTION -> NATIVE_DEPOSIT
 */
export enum DepositDrawerSubScreen {
  NATIVE_DEPOSIT = "NATIVE_DEPOSIT",
  AMOUNT_SELECTION = "AMOUNT_SELECTION",
  METHOD_SELECTION = "METHOD_SELECTION",
}
