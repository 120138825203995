import "./DailyChestClaimIcon.css";

interface DailyChestClaimIconProps {
  claimable: boolean;
}

const DailyChestClaimIcon = ({ claimable }: DailyChestClaimIconProps) => {
  return (
    <>
      {claimable ? (
        <div className="relative flex flex-col items-center align-middle">
          {/* GLOWING */}
          <img
            src="/images/daily/glow-chest-inner.png"
            alt="daily reward"
            className="daily-chest__glow absolute top-5 w-[45px] object-contain"
          />
          <img
            src="/images/daily/glow-chest-red.png"
            alt="daily reward"
            className="daily-chest__glow absolute -top-1 w-[45px] object-contain"
          />
          <img
            src="/images/daily/glow-chest-ruby.png"
            alt="daily reward"
            className="daily-chest__glow-ruby absolute left-[17.5px] top-[27px] z-[1] w-[26px] object-contain"
          />

          {/* CHEST */}
          <img
            src="/images/daily/chest-top.png"
            alt="daily reward"
            className="daily-chest-icon__claimable absolute top-2 h-[18px] w-[43px] object-contain"
          />
          <img
            src="/images/daily/chest-bottom.png"
            alt="daily reward"
            className="absolute top-4 h-[30px] w-[45px] object-contain"
          />
        </div>
      ) : (
        <div className="relative flex flex-col items-center align-middle">
          <img
            src="/images/daily/chest-disabled.png"
            alt="daily reward"
            className="h-[48px] w-[48px] object-contain opacity-60 grayscale filter"
          />
        </div>
      )}
    </>
  );
};

export default DailyChestClaimIcon;
