import { siteApi } from "@api";
import { ONE_DAY_IN_SECONDS } from "@constants/time";
import { useGetRewardsInfo, useServerInfo } from "@hooks/index.ts";
import { RewardStatus } from "@types";
import { checkClaimable } from "@utils";
import { WalletTxMethod } from "kz-ui-sdk";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

/**
 * Hook to get the promotion status of Daily Bonus, Cashback, and Referral
 */
const usePromotionStatus = () => {
  const { data: rewardData, isLoading: isFetchingRewardStatus } = useGetRewardsInfo();
  const { data: referralData, isLoading: isFetchingReferralStatus } = siteApi.useGetReferralInfoQuery({});
  const { data: referralClaimData, isLoading: isFetchingReferralClaimData } = siteApi.useGetReferralAvailableClaimQuery(
    {},
    {
      skip: !referralData?.referralConfig?.visibility,
    },
  );

  //Countdown logic for Daily bonus
  const countingDownRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const [remainingSeconds, setRemainingSeconds] = useState(ONE_DAY_IN_SECONDS);
  const serverInfo = useServerInfo();
  const { data } = useGetRewardsInfo();
  const [dailyClaimStatus, setDailyClaimStatus] = useState<RewardStatus>(RewardStatus.LOCKED);

  const stopCountdown = useCallback(() => {
    clearInterval(countingDownRef.current);
  }, []);

  const onSecondTick = useCallback(() => {
    setRemainingSeconds((prev) => {
      if (prev === 1) {
        startCountdown();
        setDailyClaimStatus(RewardStatus.CLAIMABLE);
      }
      return prev - 1;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stopCountdown]);

  const startCountdown = useCallback(() => {
    const now = new Date();
    const delay = 1000 - now.getMilliseconds();
    setTimeout(() => {
      stopCountdown();
      countingDownRef.current = setInterval(onSecondTick, 1000);
    }, delay);
  }, [onSecondTick, stopCountdown]);

  useEffect(() => {
    if (data?.[WalletTxMethod.DailyBonus] && serverInfo?.utcOffset) {
      const remainingSecs = data[WalletTxMethod.DailyBonus].remainingSeconds;
      setRemainingSeconds(remainingSecs);
      if (remainingSecs > 0) {
        setDailyClaimStatus(RewardStatus.LOCKED);
        startCountdown();
      } else {
        setDailyClaimStatus(RewardStatus.CLAIMABLE);
        stopCountdown();
      }
    }
  }, [data, serverInfo?.utcOffset, startCountdown, stopCountdown]);

  useEffect(() => {
    return () => {
      clearInterval(countingDownRef.current);
    };
  }, []);

  // Check daily login & reward status
  const { isCashbackAvailable, isCashbackClaimable, isDailyBonusAvailable, isDailyBonusClaimable } = useMemo(() => {
    const checkClaimableResult = checkClaimable(rewardData);
    const isCashbackClaimable =
      checkClaimableResult[WalletTxMethod.DailyCashBack] ||
      checkClaimableResult[WalletTxMethod.InstantCashBack] ||
      checkClaimableResult[WalletTxMethod.WeeklyCashBack] ||
      checkClaimableResult[WalletTxMethod.MonthlyCashBack];

    const isCashbackAvailable =
      !!rewardData?.[WalletTxMethod.DailyCashBack] ||
      !!rewardData?.[WalletTxMethod.InstantCashBack] ||
      !!rewardData?.[WalletTxMethod.WeeklyCashBack] ||
      !!rewardData?.[WalletTxMethod.MonthlyCashBack];

    return {
      isCashbackClaimable,
      isCashbackAvailable,
      isDailyBonusAvailable: !!rewardData?.[WalletTxMethod.DailyBonus],
      isDailyBonusClaimable: checkClaimableResult[WalletTxMethod.DailyBonus],
    };
  }, [rewardData]);

  // Check referral status
  const { isReferralAvailable, isReferralClaimable } = useMemo(() => {
    return {
      isReferralAvailable: !!referralData?.referralConfig?.visibility,
      isReferralClaimable:
        !!referralData?.referralConfig &&
        referralClaimData?.availableAmount?.gt(0) &&
        referralClaimData?.availableAmount?.gte(referralData?.referralConfig?.minComAmt ?? 0),
    };
  }, [referralData, referralClaimData]);

  // combine status
  const { isAnyAvailable, isAnyClaimable } = useMemo(() => {
    return {
      isAnyAvailable: isCashbackAvailable || isDailyBonusAvailable || isReferralAvailable,
      isAnyClaimable: isCashbackClaimable || isDailyBonusClaimable || isReferralClaimable,
    };
  }, [
    isCashbackAvailable,
    isCashbackClaimable,
    isDailyBonusAvailable,
    isDailyBonusClaimable,
    isReferralAvailable,
    isReferralClaimable,
  ]);

  const isFetching = isFetchingRewardStatus || isFetchingReferralStatus || isFetchingReferralClaimData;

  return {
    // cashback
    isCashbackAvailable,
    isCashbackClaimable,
    // daily login
    isDailyBonusAvailable,
    isDailyBonusClaimable,
    dailyClaimStatus,
    remainingSeconds,
    // referral
    isReferralAvailable,
    isReferralClaimable,
    isAnyAvailable,
    isAnyClaimable,
    isFetching,
  };
};

export default usePromotionStatus;
