import { siteApi } from "@api";
import { BackButton, ClaimButton, FlyingCoins, FlyingCoinsRef } from "@components";
import { ClaimButtonRef } from "@components/inputs/ClaimButton/ClaimButton";
import { BALANCE_BADGE_TOP_ID, DEFAULT_ERROR_MESSAGE } from "@constants";
import { Paths } from "@constants/paths";
import { useProfileBalance, usePromotionStatus } from "@hooks";
import { getThemeAsset } from "@utils";
import { t } from "i18next";
import { PageTitle, WalletTxMethod, bnOrZero, cn, delay, useHandleApiResponse } from "kz-ui-sdk";
import { throttle } from "lodash";
import { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from "react";
import AspectRatio from "react-aspect-ratio";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import "./DailyRewardPage.css";
import { RollingNumber, RollingNumberRef } from "./components";

interface DailyRewardPageProps extends PropsWithChildren {}

const DailyRewardPage = ({}: DailyRewardPageProps) => {
  const refRollingNumbers = useRef<RollingNumberRef>(null);
  const refFlyingCoins = useRef<FlyingCoinsRef>(null);
  const refClaimButton = useRef<ClaimButtonRef>(null);

  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const [isWheelAnimated, setIsWheelAnimated] = useState(false);
  const [isSlotAnimated, setIsSlotAnimated] = useState(false);
  const [claimedPoint, setClaimedPoint] = useState<number | null>(null);
  const [isBtnClaimable, setIsBtnClaimable] = useState(true);

  const { refetch: refetchBalance } = useProfileBalance();
  const navigate = useNavigate();
  const { isDailyBonusClaimable } = usePromotionStatus();
  const { handleApiResponse } = useHandleApiResponse({
    toast,
  });

  const [refetchRewardStatus] = siteApi.useLazyGetRewardsStatusQuery();
  const [claimReward, result] = siteApi.useClaimRewardMutation();

  useEffect(() => {
    const displayWheel = setTimeout(() => {
      setIsWheelAnimated(true);
    }, 7200);

    const displaySlotReward = setTimeout(() => {
      setIsSlotAnimated(true);
    }, 1500);

    // Show the button after the wheel animation
    const wheelTimeout = setTimeout(() => {
      setIsButtonVisible(true);
    }, 7000);

    return () => {
      clearTimeout(displayWheel);
      clearTimeout(displaySlotReward);
      clearTimeout(wheelTimeout);
    };
  }, []);

  const fetchClaimReward = async () => {
    // check if the reward is claimable
    if (!isDailyBonusClaimable) {
      toast.error(t("already claimed"));
      navigate(Paths.PRIVATE.HOME, { replace: true });
      return;
    }

    const result = await claimReward({ query: { type: WalletTxMethod.DailyBonus } });
    handleApiResponse(result, {
      onSuccess: () => {
        setClaimedPoint(Number(result?.data?.model.reqAmount));
        delay(1000).then(() => {
          refRollingNumbers.current?.rollAll();
        });
      },
      onError: () => {
        // Redirect to home page
        navigate(Paths.PRIVATE.HOME, { replace: true });
      },
      errorMessageResolver: (error) => {
        return t(error?.data?.error?.message ?? DEFAULT_ERROR_MESSAGE);
      },
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledClaim = useCallback(throttle(fetchClaimReward, 1000, { leading: true, trailing: false }), []);

  // Claim on page load
  useEffect(() => {
    throttledClaim();
  }, [throttledClaim]);

  const handleClaim = useCallback(async () => {
    refFlyingCoins.current?.start();
    setIsBtnClaimable(false);

    delay(2500).then(() => {
      navigate(Paths.PRIVATE.HOME, {
        replace: true,
      });
    });
    delay(2400).then(() => {
      refetchBalance();
      refetchRewardStatus();
    });
  }, [navigate, refetchBalance, refetchRewardStatus]);

  const flyingCoinConfig = useMemo(() => {
    // find the first level that the balance is greater than or equal to
    const balanceBn = bnOrZero(claimedPoint);
    for (const level of FLYING_COINS_CONFIGS) {
      if (balanceBn.gte(level.condition)) {
        return level;
      }
    }
    return {
      condition: 0,
      flyingCoins: 0,
    };
  }, [claimedPoint]);

  if (!isDailyBonusClaimable) return null;

  return (
    <div className="relative -mx-5 mt-[-16px] h-screen w-screen max-w-md overflow-hidden">
      <PageTitle
        label={t("Daily Login")}
        variant="gradient-purple"
        backButton={<BackButton />}
        className="absolute left-0 top-0 mt-4 w-full px-5"
        classes={{
          "root&": "z-50",
          "label&": "whitespace-nowrap will-change-transform",
          "labelOverlay&": "whitespace-nowrap",
        }}
      />
      {/* BG Wheel */}
      <AspectRatio
        ratio={1}
        className={cn(
          "absolute left-1/2 top-0 z-40 w-[110%] max-w-md -translate-x-1/2 bg-fixed bg-no-repeat will-change-transform",
          isWheelAnimated ? FRAME_CLASSES.bgWheel : FRAME_CLASSES.bgWheelPreClaim,
        )}
      >
        <img
          src="/images/daily/bg-wheel.png"
          className={cn("h-full w-full origin-center")}
          alt=""
        />
      </AspectRatio>
      <div
        className={cn(
          "daily-reward-page__container--pre-claimable absolute top-[-80px] z-40 flex h-full w-full items-center justify-center",
        )}
      >
        {/* Chest image */}
        <div
          className={cn(
            "absolute top-[130px] z-10 h-[285px] w-[305px] bg-cover bg-no-repeat opacity-100 transition [filter:drop-shadow(0px_10px_16px_black)]",
          )}
          style={{
            backgroundImage: `url(${getThemeAsset("daily-login/chest.png")})`,
          }}
        ></div>
        {/* rolling number */}
        <div
          className={cn(
            "absolute top-[275px] z-50 w-full will-change-transform",
            isSlotAnimated ? FRAME_CLASSES.amountContainer : FRAME_CLASSES.amountContainerPreClaim,
          )}
        >
          {
            <RollingNumber
              ref={refRollingNumbers}
              claimedPoint={claimedPoint}
            />
          }
        </div>
        <div className="absolute left-1/2 top-[162px] z-50 h-[128px] w-[263px] -translate-x-1/2">
          <img
            src="/images/daily/chest-top-shadow.png"
            className="h-full w-full"
            alt=""
          />
        </div>

        <div
          className={cn("absolute left-1/2 top-[162px] z-20 h-[128px] w-[263px] -translate-x-1/2 bg-black/20", {
            "animate-pulse": isSlotAnimated,
          })}
        ></div>

        <FlyingCoins
          coinImgSrc={"/images/icon-coin.png"}
          sourceCoinSize={28}
          randomSizeRange={14}
          targetCoinSize={16}
          count={flyingCoinConfig.flyingCoins}
          targetId={BALANCE_BADGE_TOP_ID}
          className={cn("absolute left-1/2 top-[284px] z-10 h-[40px] w-[200px] -translate-x-1/2")}
          ref={refFlyingCoins}
          randomHorizontalRange={60}
          randomVerticalRange={70}
          delayIn={0.05}
          overlay={true}
          duration={1.5}
          removeIn={4.4}
        />

        {/*Bottom shadow*/}

        <div
          className={cn(
            "absolute top-[425px] z-50 w-full px-5 will-change-transform",
            isButtonVisible ? FRAME_CLASSES.bgBtn : FRAME_CLASSES.bgBtnPreClaim,
          )}
        >
          <ClaimButton
            size="lg"
            classes={{
              text: "text-base",
            }}
            onClick={handleClaim}
            withCoin={isBtnClaimable}
            withShine={isBtnClaimable}
            claimable={isBtnClaimable}
            text={`${t("Claim Now")}!`}
            loading={result.isLoading}
            ref={refClaimButton}
          />
        </div>
      </div>
    </div>
  );
};

const FLYING_COINS_CONFIGS = [
  {
    condition: 10000,
    flyingCoins: 15,
  },
  {
    condition: 1000,
    flyingCoins: 12,
  },
  {
    condition: 100,
    flyingCoins: 9,
  },
  {
    condition: 50,
    flyingCoins: 7,
  },
  {
    condition: 0.01,
    flyingCoins: 5,
  },
];

const FRAME_CLASSES = {
  bgWheelPreClaim: "daily-reward-page__bg-wheel--pre-claimable",
  bgWheel: "daily-reward-page__bg-wheel--claimable",
  bgBtnPreClaim: "daily-reward-page__btn--pre-claimable",
  bgBtn: "daily-reward-page__btn--claimable",
  amountContainer: "daily-reward-page__amount-container",
  amountContainerPreClaim: "daily-reward-page__amount-container--pre-claimable",
};

export default DailyRewardPage;
