import { NativeBankTransfer, NativeQR } from "@components/utils/DepositDrawer/components/NativeDeposit/components";
import { DepositMethodType, FundingMethodModel, FundingStatus, TopupResponse } from "kz-ui-sdk";
import { useMemo } from "react";
import { NativeDepositStatus } from "../../types";
import "./NativeDeposit.css";

interface StepNativeDepositProps {
  topUpResponse: TopupResponse;
  onStatusChange?: (status: NativeDepositStatus) => void;
  method?: FundingMethodModel;
}

const NativeDeposit = ({ topUpResponse, onStatusChange, method }: StepNativeDepositProps) => {
  const paymentMethod = useMemo(() => {
    return topUpResponse.fundingTx?.payData?.method;
  }, [topUpResponse.fundingTx?.payData?.method]);

  // This logic from Backend side, MP requires slip if method is BankTransfer and status is PendingProof
  const isRequireSlip = useMemo(() => {
    return topUpResponse.fundingTx?.status === FundingStatus.PendingProof;
  }, [topUpResponse.fundingTx?.status]);

  if (!paymentMethod || !topUpResponse.fundingTx) return null;

  switch (paymentMethod) {
    case DepositMethodType.BankTransfer:
    case DepositMethodType.BankTransferZero:
    case DepositMethodType.BankTransferNative:
      return (
        <NativeBankTransfer
          fundingTx={topUpResponse.fundingTx}
          onNativeStatusChange={onStatusChange}
          method={method}
          requireSlip={isRequireSlip}
        />
      );

    case DepositMethodType.QrCode:
      return <NativeQR fundingTx={topUpResponse.fundingTx} />;
    default:
      return null;
  }
};

export default NativeDeposit;
