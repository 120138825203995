import { cn } from "kz-ui-sdk";
import "./SuccessHeartbeat.css";

interface SuccessHeartbeatProps {
  className?: string;
  imgSrc?: string;
  imgClassName?: string;
}

export default function SuccessHeartbeat({
  className,
  imgSrc = "/images/icon-success.png",
  imgClassName,
}: SuccessHeartbeatProps) {
  return (
    <div className={cn("check-heartbeat", className)}>
      <div
        className="check-heartbeat__ring"
      />
      <img
        className={cn("check_image", imgClassName)}
        src={imgSrc}
        alt="checkmark"
      />
    </div>
  );
}
