import { getBrandResource } from "@utils";
import { cn, PropsWithClassName } from "kz-ui-sdk";

interface BrandBannerProps extends PropsWithClassName {}

const BrandBanner = ({ className }: BrandBannerProps) => {
  return (
    <div
      className={cn(
        "bg-darkBrand-900 relative w-full rounded-lg border border-white/10 text-base font-bold leading-5",
        className,
      )}
    >
      <div className="relative pt-[31.5%]">
        <img
          className="absolute inset-0 z-10 h-full w-full rounded-lg border-hidden object-cover object-center"
          src={getBrandResource("main_banner.png")}
          alt="main-banner"
        />
      </div>
    </div>
  );
};

export default BrandBanner;
