import { cn, delay, fAmount } from "kz-ui-sdk";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";

export interface RollingNumberRef {
  rollAll: () => void;
  stopAll?: () => void;
}

interface RollingNumberProps {
  claimedPoint?: number | null;
}

const DIGITS = ["9", "8", "7", "6", "5", "4", "3", "2", "1", "0", ".", ","];

const RollingNumber = forwardRef<RollingNumberRef, RollingNumberProps>(({ claimedPoint }, ref) => {
  const claimedPointRef = useRef(claimedPoint);
  const currentClaimedPoint = claimedPointRef.current;
  const amountStr = toRollingFormat(currentClaimedPoint ?? 0);
  const amountStrLength = amountStr.length;

  const [isShifted, setIsShifted] = useState(false);

  useEffect(() => {
    claimedPointRef.current = claimedPoint;
  }, [claimedPoint]);

  const animations = useRef<any>([]);

  const startSlotAnimation = (ref: HTMLDivElement | null, index: number) => {
    const factor = 5 + Math.floor(Math.random() * 10);
    const section = 100 / DIGITS.length;
    let spinTimer: number;

    // Spin function using requestAnimationFrame for smoother animation
    const spin = () => {
      if (ref) ref.style.transition = "";
      let value = 0;

      const animate = () => {
        if (value <= -(section * DIGITS.length)) {
          value = 0;
        }
        value -= section / factor;
        if (ref) {
          ref.style.transform = `translateY(${-value}%)`;
          ref.style.opacity = "1";
        }
        spinTimer = requestAnimationFrame(animate);
      };
      spinTimer = requestAnimationFrame(animate);
    };

    const stop = (delay: number | undefined) => {
      setTimeout(() => {
        cancelAnimationFrame(spinTimer);
        if (ref) {
          ref.style.transition = "transform 1.5s ease-out";
          const claimedPointString = toRollingFormat(claimedPointRef.current ?? 0);
          const targetNumber = claimedPointString[index];
          const targetIndex = DIGITS.length - DIGITS.indexOf(targetNumber);
          const targetPosition = section * targetIndex - section;

          // reset the position of the wheel
          ref.style.opacity = "0";
          ref.style.transform = `translateY(-128px)`;
          ref.style.transitionDuration = "0.1s";
          ref.style.transitionProperty = "transform";

          // scroll down to the target position
          setTimeout(() => {
            ref.style.transitionDuration = "1.5s";
            ref.style.transform = `translateY(${targetPosition}%)`;
            ref.style.opacity = "1";
            if (targetPosition === -81.81818181818183 && claimedPointString[index] === "-") {
              ref.style.transition = "transform 1.5s ease-out";
              ref.style.opacity = "0";
            }
          }, 100);
        }
      }, delay);
    };

    return { spin, stop };
  };

  const rollAll = useCallback(() => {
    animations.current = Array.from({
      length: amountStrLength,
    }).map((_, index) => {
      const reverseIndex = amountStrLength - index - 1;
      const wheelRef = document.getElementById(["daily-reward-wheel", reverseIndex].join("-")) as HTMLDivElement;
      const animation = startSlotAnimation(wheelRef, reverseIndex);
      animation.spin();
      delay(2500).then(() => animation.stop(index * 300));
      delay(6500).then(() => setIsShifted(true));
      return animation;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amountStrLength]);

  useImperativeHandle(ref, () => ({
    rollAll,
  }));

  return (
    <>
      <div className="absolute bottom-[-10px] flex h-[120px] w-full items-baseline justify-center overflow-hidden">
        <div
          className={cn(
            "absolute left-1/2 top-[60px] z-20 h-[60px] w-[263px] -translate-x-1/2 bg-[linear-gradient(0deg,#00000091,transparent)] transition-opacity",
            {
              "opacity-0": isShifted,
            },
          )}
        ></div>

        <div className="-translate-y-[calc(100%-128px)]">
          {Array.from({
            length: amountStrLength,
          }).map((_, wheelIndex) => {
            const currentChar = amountStr[wheelIndex];
            const isPunctuation = [".", ","].includes(currentChar);
            const isEmpty = currentChar === "-" || currentChar === undefined;

            return (
              <div
                className={cn(
                  "inline-block max-w-[29px] animate-fade-in-3s overflow-hidden transition-all duration-500 will-change-transform",
                  {
                    "mx-[-8px]": isShifted && isPunctuation,
                    "drop-shadow-[0px_0px_5px_rgba(245,193,42,0.38)]": isShifted,
                    "!max-w-0": isShifted && isEmpty,
                  },
                )}
                key={["wheel", wheelIndex].join("-")}
              >
                <section>
                  <div
                    className={cn("absolute w-[29px] text-center")}
                    style={{
                      top: 0,
                      position: "relative",
                    }}
                    id={["daily-reward-wheel", wheelIndex].join("-")}
                  >
                    {DIGITS.map((num, index) => (
                      <div
                        className="animate-fade-in pb-6 will-change-transform"
                        key={["wheel", wheelIndex, num].join("-")}
                      >
                        <p
                          className={cn(
                            "text-center !font-noto-thai text-[46px] font-bold tracking-[-2.3px] text-[#ffd966] [text-shadow:0_4px_10px_rgba(0,0,0,0.25)]",
                            {
                              "text-gradient-gold-2": isShifted,
                            },
                          )}
                        >
                          {num}
                        </p>
                      </div>
                    ))}
                  </div>
                </section>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
});

RollingNumber.displayName = "RollingNumber";

/**
 * Transforms a number into a formatted string with hyphens based on its length.
 * Logic for rolling number in slot machine
 * @example
 * Input: 0.10 -> 0-.-1-0
 * Input: 1,00 -> 1-,-0-0
 * Input: 10.00 -> 1-0.0-0
 * Input: 100.00 -> 100-.00
 * Input: 1,000.00 -> 1,000.00
 */
const toRollingFormat = (input: number) => {
  const formattedInput = fAmount(input); // Converts 1000 to "1,000.00"
  const inputLength = formattedInput.length;

  if (inputLength === 3) {
    return `${formattedInput[0]}-${formattedInput[1]}-${formattedInput[2]}`;
  } else if (inputLength === 4) {
    return `${formattedInput[0]}-${formattedInput[1]}-${formattedInput[2]}-${formattedInput[3]}`;
  } else if (inputLength === 5) {
    return `${formattedInput[0]}-${formattedInput[1]}${formattedInput[2]}${formattedInput[3]}-${formattedInput[4]}`;
  } else if (inputLength === 6) {
    return `${formattedInput[0]}${formattedInput[1]}${formattedInput[2]}-${formattedInput[3]}${formattedInput[4]}${formattedInput[5]}`;
  }

  return formattedInput;
};

export default RollingNumber;
