import { openDeposit } from "@components/utils/DepositDrawer";
import { useProfileBalance } from "@hooks";
import BigNumber from "bignumber.js";
import { Button, cn, delay, fAmount } from "kz-ui-sdk";
import { useMemo, useState } from "react";
import CountUp from "react-countup";
import { MdAddCircle, MdRestartAlt } from "react-icons/md";
import "./BalanceBadge.css";

const BALANCE_RUN_UP_DURATION = 2.5;

interface BalanceBadgeProps {
  id?: string;
  delayUpdate?: number;
  skipAutoUpdate?: boolean;
}

export default function BalanceBadge({ id, delayUpdate, skipAutoUpdate }: BalanceBadgeProps) {
  const { refetch, isLoading, prevBalance, nextBalance } = useProfileBalance({
    delayUpdate,
    skipAutoUpdate,
  });

  const countUpAnimationDuration = useMemo(() => {
    // only show animation when the balance is increased
    return prevBalance.toNumber() < nextBalance.toNumber() ? BALANCE_RUN_UP_DURATION : 0.01;
  }, [prevBalance, nextBalance]);
  const [arrowAnimation, setArrowAnimation] = useState("balance-badge__arrow--idle");

  const onClickRefetch = () => {
    refetch();
    setArrowAnimation("balance-badge__arrow--rotate");
    delay(400).then(() => {
      setArrowAnimation("balance-badge__arrow--idle");
    });
  };

  const onClickDeposit = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    openDeposit();
  };

  return (
    <Button
      icon={<MdRestartAlt className={cn("h-4 w-4 text-primary-light will-change-transform", arrowAnimation)} />}
      iconPosition="right"
      classes={{
        root_primary:
          "!rounded-full group border border-black/15 bg-[linear-gradient(180deg,rgba(0,0,0,0.1)_0%,rgba(0,0,0,0.25)_100%)] px-4 py-2 w-min",
        label: "!text-content-base flex flex-row gap-[3px] items-center text-sm leading-8 relative",
        "root&": "!h-6 !px-1",
      }}
      onClick={onClickRefetch}
      loading={isLoading}
    >
      <div onClick={onClickDeposit}>
        <MdAddCircle className="h-4 w-4 text-content-secondary transition-transform hover:scale-110 active:scale-100 active:opacity-90" />
      </div>
      <CountUp
        style={{
          fontSize: "12px",
        }}
        start={prevBalance.dp(2, BigNumber.ROUND_DOWN).toNumber()}
        end={nextBalance.dp(2, BigNumber.ROUND_DOWN).toNumber()}
        duration={countUpAnimationDuration}
        formattingFn={fAmount}
        // to ignore rounding from CountUp
        decimals={10}
      />
      {/*component ref*/}
      <span
        id={id}
        className="absolute right-0 top-1/2 h-4 w-4 -translate-y-1/2"
      ></span>
    </Button>
  );
}
