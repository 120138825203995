import { siteApi } from "@api";
import { useScrollPositionDetector } from "@components/layouts/ScrollBox/hooks";
import { QUERY_ALL } from "@constants";
import { cn, GameCategoryModel, TabNavigation, TabNavigationAction } from "kz-ui-sdk";
import { PropsWithChildren, useLayoutEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { CATEGORY_POPULAR_SLUG } from "../../contants";
// @ts-ignore
import useScrollOnDrag from "react-scroll-ondrag";
import "./GameCategoryList.css";

interface CategoryListProps extends PropsWithChildren {
  onSelect?: (category: GameCategoryModel | undefined) => void;
  shadowClassName?: string;
}

const ICONS_MAP: Record<string, string> = {
  arcade: "/images/games/cat-arcade.png",
  bingo: "/images/games/cat-bingo.png",
  cards: "/images/games/cat-cards.png",
  card: "/images/games/cat-cards.png",
  fishing: "/images/games/cat-fishing.png",
  slot: "/images/games/cat-slot.png",
  table: "/images/games/cat-table.png",
  lobby: "/images/games/cat-lobby.png",
  roulette: "/images/games/cat-roulette.png",
  wheel: "/images/games/cat-wheel.png",
  baccarat: "/images/games/cat-baccarat.png",
  ecasino: "/images/games/cat-ecasino.png",
  sicbo: "/images/games/cat-sicbo.png",
  live: "/images/games/cat-live.png",
  popular: "/images/games/cat-popular.svg",
};

const GameCategoryList = ({ onSelect, shadowClassName }: CategoryListProps) => {
  const { t } = useTranslation();
  const refListContainer = useRef(null);
  const { events: scrollOnDragEvents } = useScrollOnDrag(refListContainer);
  const { data: categoriesResponse, isFetching: isFetchingCategories } = siteApi.useGetGameCategoryListQuery({
    query: QUERY_ALL,
  });
  const { data: providersStatsResponse, isFetching: isFetchingStats } = siteApi.useGetGameProviderStatsQuery();

  const { checkPosition, isScrollbarAtRight, isScrollbarAtLeft } = useScrollPositionDetector(refListContainer, {
    direction: "horizontal",
  });

  const isFetching = isFetchingCategories || isFetchingStats;

  const categories = useMemo(() => {
    if (isFetching) return;
    const topCategories = [];
    let remainingCategories = [];

    // FE side need to move the popular category to the top
    for (const category of categoriesResponse?.entries ?? []) {
      if (category.slug === CATEGORY_POPULAR_SLUG) {
        topCategories.push(category);
      } else {
        remainingCategories.push(category);
      }
    }

    // Should hide empty categories
    remainingCategories = remainingCategories.filter((category) => {
      for (const provider of providersStatsResponse?.providers ?? []) {
        const hasGame = !!provider.categories.find(
          (providerCategory) => providerCategory.id === category.id && providerCategory.total > 0,
        );
        if (hasGame) return true;
      }
      return false;
    });

    return [...topCategories, ...remainingCategories];
  }, [categoriesResponse?.entries, isFetching, providersStatsResponse?.providers]);

  const categoryOptions: TabNavigationAction[] | undefined = useMemo(() => {
    return categories?.map((category) => ({
      id: category.id,
      icon: (!!category?.logo?.url || ICONS_MAP[category.slug as string]) && (
        <img
          src={category?.logo?.url ?? ICONS_MAP[category.slug as string]}
          alt="popular"
          className="h-5 w-5"
        />
      ),
      label: t(category.name),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  const handleOnScrollList = () => {
    checkPosition();
  };

  useLayoutEffect(() => {
    checkPosition();
  }, [checkPosition, categoryOptions?.length]);

  return (
    <>
      {!!categoryOptions?.length && !isFetching && (
        <div className="relative w-full overflow-x-auto">
          <div
            ref={refListContainer}
            {...scrollOnDragEvents}
            className="scrollbar-none w-full overflow-x-auto pl-5"
            onScroll={handleOnScrollList}
          >
            <TabNavigation
              tabActions={categoryOptions}
              variant="secondary"
              autoSelect
              onChange={(tab) => {
                const category = categories?.find((cate) => cate.id === tab?.id);
                onSelect?.(category);
              }}
            />
          </div>
          {!isScrollbarAtLeft && (
            <div
              className={cn(
                "pointer-events-none absolute left-0 top-0 h-full w-14 animate-fade-in bg-gradient-to-r from-darkBrand-800  to-transparent",
                shadowClassName,
              )}
            />
          )}
          {!isScrollbarAtRight && (
            <div
              className={cn(
                "pointer-events-none absolute right-0 top-0 h-full w-14 animate-fade-in bg-gradient-to-l from-darkBrand-800  to-transparent",
                shadowClassName,
              )}
            />
          )}
        </div>
      )}

      {isFetching && (
        <div className="xs:scrollbar-primary flex w-full flex-row gap-1.5 overflow-x-auto pl-2">
          {Array.from({ length: 5 }).map((_, index) => (
            <span
              key={`category-skeleton-${index}`}
              className="bg-darkBrand-900 h-[56px] w-[64px] flex-shrink-0 animate-pulse rounded-md"
            />
          ))}
        </div>
      )}
    </>
  );
};

export default GameCategoryList;
