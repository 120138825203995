import { siteApi } from "@api";
import { IconNotePad2 } from "@assets";
import { BackButton } from "@components";
import { t } from "i18next";
import {
  AccountVariableContent,
  AccountVariableKey,
  AccountVariableModel,
  EmptyState,
  HTMLSanitizer,
  PageTitle,
  Skeleton,
} from "kz-ui-sdk";
import { useMemo } from "react";
import "./html-styles.css";

export const CashbackTNCPage = () => {
  const { data, isFetching } = siteApi.useGetAccountVariableQuery({
    query: {
      key: AccountVariableKey.CashBackTNC,
    },
  });

  const content: string = useMemo(() => {
    const cashbackTNC = data?.entries?.find((entry) => {
      return entry.key === AccountVariableKey.CashBackTNC;
    }) as AccountVariableModel<AccountVariableContent[AccountVariableKey.CashBackTNC]> | undefined;
    return cashbackTNC?.content?.text ?? "";
  }, [data]);

  return (
    <div className="flex flex-col">
      <PageTitle
        label={t("Cashback")}
        backButton={<BackButton />}
        className="mb-4"
      />

      {!content && !isFetching && (
        <EmptyState
          icon={<IconNotePad2 className="text-content-secondary" />}
          title={t("Terms & Conditions")}
          subTitle={t("is being updated.")}
        />
      )}

      {!isFetching && (
        <HTMLSanitizer
          html={content}
          className="term-section animate-fade-in"
        />
      )}

      {isFetching && (
        <Skeleton
          className="mb-6 !h-36"
          count={4}
        />
      )}
    </div>
  );
};

export default CashbackTNCPage;
