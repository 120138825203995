import "./ReferralIcon.css";

interface ReferralIconProps {
  claimable: boolean;
}

const ReferralIcon = ({ claimable }: ReferralIconProps) => {
  return (
    <div className="h-[48px] overflow-hidden">
      {claimable ? (
        <div className="relative flex flex-col items-center align-middle">
          {/*CAT*/}
          <img
            src="/images/referral/referral-cat.png"
            alt="referral"
            className="referral-icon__cat absolute top-[6px] z-[1] h-[40px] w-[43px] object-contain"
          />
          <img
            src="/images/referral/referral-cat-paw-1.png"
            alt="referral"
            className="referral-cat-icon__paw absolute right-[42px] top-[15px] h-[23px] w-[17px] rotate-12 object-contain"
          />
          <img
            src="/images/referral/referral-cat-paw-2.png"
            alt="referral"
            className="referral-icon__bowl absolute left-[40px] top-[26px] z-[1] h-[15px] w-[15px] object-contain"
          />
          <img
            src="/images/referral/referral-cat-bling.png"
            alt="referral"
            className="referral-cat-icon__bling absolute left-[38px] top-[10px] h-[16px] w-[16px] object-contain"
          />

          {/*BOWL*/}
          <img
            src="/images/referral/referral-bowl.png"
            alt="referral"
            className="referral-icon__bowl absolute top-8 z-[1] w-[45px] object-contain"
          />

          <img
            src="/images/referral/referral-bowl-top.png"
            alt="referral"
            className="referral-icon__bowl absolute top-[29px] w-[45px] object-contain"
          />
        </div>
      ) : (
        <div className="relative flex flex-col items-center align-middle">
          <img
            src="/images/referral/referral-cat.png"
            alt="referral"
            className="absolute top-[6px] z-[1] h-[40px] w-[43px] object-contain"
          />
          <img
            src="/images/referral/referral-cat-paw-3.png"
            alt="referral"
            className="absolute right-[39px] top-[26px] z-[2] h-[15px] w-[17px] rotate-6 object-contain"
          />
          <img
            src="/images/referral/referral-cat-paw-2.png"
            alt="referral"
            className="absolute left-[40px] top-[26px] z-[2] h-[15px] w-[15px] object-contain"
          />
          <img
            src="/images/referral/referral-cat-bling.png"
            alt="referral"
            className="absolute left-[38px] top-[10px] h-[16px] w-[16px] object-contain"
          />

          {/*BOWL*/}
          <img
            src="/images/referral/referral-bowl.png"
            alt="referral"
            className="absolute top-8 z-[1] w-[45px] object-contain"
          />

          <img
            src="/images/referral/referral-bowl-top.png"
            alt="referral"
            className="absolute top-[29px] w-[45px] object-contain"
          />
        </div>
      )}
    </div>
  );
};

export default ReferralIcon;
