import "./CashbackIcon.css";

interface CashbackIconProps {
  claimable: boolean;
}

const cashbackCoinPosition1 = [
  { left: "23px", top: "2px" },
  { left: "32px", top: "14px" },
];

const cashbackCoinPosition2 = [
  { left: "17px", top: "10px" },
  { left: "27px", top: "6px" },
];

const cashbackCoinUnclaimable = [
  { left: "20px", top: "3px" },
  { left: "14px", top: "12px" },
  { left: "24px", top: "6px" },
  { left: "28px", top: "12px" },
];

const CashbackIcon = ({ claimable }: CashbackIconProps) => {
  return (
    <div className="h-[48px] overflow-hidden">
      {claimable ? (
        <div className="cashback-wallet-icon__claimable relative flex flex-col items-center align-middle">
          {/*GLOW*/}
          <img
            src="/images/cashback/cashback-wallet-glow.png"
            alt="cashback"
            className="cashback-wallet-icon__glow absolute -top-1 left-[7px] w-[45px] -rotate-[9deg] object-contain"
          />
          {/*COINS*/}
          {cashbackCoinPosition1.map((position, index) => (
            <img
              key={index}
              src="/images/cashback/coin.png"
              alt="cashback"
              className={`cashback-wallet-icon__coin absolute h-[14px] w-[14px] object-contain`}
              style={{ left: position.left, top: position.top }}
            />
          ))}
          {cashbackCoinPosition2.map((position, index) => (
            <img
              key={index}
              src="/images/cashback/coin.png"
              alt="cashback"
              className={`cashback-wallet-icon__coin-2 absolute h-[14px] w-[14px] object-contain`}
              style={{ left: position.left, top: position.top }}
            />
          ))}

          {/*WALLET*/}
          <img
            src="/images/cashback/cashback-wallet.png"
            alt="cashback"
            className="absolute top-3 w-[45px] -rotate-[9deg] object-contain"
          />
        </div>
      ) : (
        <div className="relative -ml-1 mt-2 flex -rotate-[9deg] flex-col items-center align-middle">
          {/*COINS*/}
          {cashbackCoinUnclaimable.map((position, index) => (
            <img
              key={index}
              src="/images/cashback/coin.png"
              alt="cashback"
              className="absolute h-[14px] w-[14px] object-contain"
              style={{ left: position.left, top: position.top }}
            />
          ))}
          {/*GLOW*/}
          <img
            src="/images/cashback/cashback-wallet-glow.png"
            alt="cashback"
            className="absolute -top-1 left-[7px] w-[48px] object-contain"
          />
          {/*WALLET*/}
          <img
            src="/images/cashback/cashback-wallet.png"
            alt="cashback"
            className="absolute left-1 top-3 w-[45px] object-contain"
          />
        </div>
      )}
    </div>
  );
};

export default CashbackIcon;
