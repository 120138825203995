import { usePrevious } from "@uidotdev/usehooks";
import { useCallbackRef } from "kz-ui-sdk";
import { useEffect } from "react";
import { Location, useLocation } from "react-router-dom";

function getLocationPathCompare(location: Location) {
  return location.pathname + location.search + location.hash;
}

export default function useRouteChange({
  onChange,
}: {
  onChange?: (prevLocation: Location, newLocation: Location) => void;
}) {
  const location = useLocation();
  const cbRefOnchange = useCallbackRef(onChange);
  const prevLocation = usePrevious(location);

  useEffect(() => {
    if (prevLocation && getLocationPathCompare(prevLocation) !== getLocationPathCompare(location)) {
      cbRefOnchange?.(location, prevLocation);
    }
  }, [cbRefOnchange, location, prevLocation]);

  return null;
}
