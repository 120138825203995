import {
  MarketingDataCollector,
  PrivateRouteLayout,
  PublicRouteLayout,
  RootRouteLayout,
  Toaster,
  WSRouteLayout,
} from "@components";
import { Paths } from "@constants";
import {
  BankAccountsPage,
  BetHistoryPage,
  CashbackPage,
  CashbackTNCPage,
  DailyRewardPage,
  DepositCallbackPage,
  ForgotPINPage,
  HomePage,
  LanguagePage,
  LoginPage,
  MaintenancePage,
  OnboardResetPINPage,
  OnboardUpdateBankPage,
  OnboardingPage,
  PrizesPage,
  ProfilePage,
  ReferralCallbackPage,
  ReferralPage,
  RegisterPage,
  TermConditionsPage,
  TransactionPage,
  UpdatePINPage,
} from "@views";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

const AppRouter = () => {
  return (
    <>
      <BrowserRouter>
        <Toaster />
        <MarketingDataCollector />
        <RootRouteLayout>
          <Routes>
            {/*PRIVATE ROUTES*/}
            <Route element={<PrivateRouteLayout />}>
              <Route element={<WSRouteLayout />}>
                <Route
                  path={Paths.PRIVATE.HOME}
                  element={<HomePage />}
                />
                <Route
                  path={Paths.PRIVATE.PRIZES}
                  element={<PrizesPage />}
                />
                <Route
                  path={Paths.PRIVATE.PROFILE}
                  element={<ProfilePage />}
                />
                <Route
                  path={Paths.PRIVATE.DAILY_REWARD}
                  element={<DailyRewardPage />}
                />
                <Route
                  path={Paths.PRIVATE.CASHBACK}
                  element={<CashbackPage />}
                />
                <Route
                  path={Paths.PRIVATE.CASHBACK_TNC}
                  element={<CashbackTNCPage />}
                />
                <Route
                  path={Paths.PRIVATE.TRANSACTION}
                  element={<TransactionPage />}
                />
                <Route
                  path={Paths.PRIVATE.LANGUAGE}
                  element={<LanguagePage />}
                />
                <Route
                  path={Paths.PRIVATE.BET_HISTORY}
                  element={<BetHistoryPage />}
                />
                <Route
                  path={Paths.PRIVATE.UPDATE_BANK_FORCE}
                  element={<OnboardUpdateBankPage />}
                />
                <Route
                  path={Paths.PRIVATE.UPDATE_PIN_FORCE}
                  element={<OnboardResetPINPage />}
                />
                <Route
                  path={Paths.PRIVATE.UPDATE_PIN}
                  element={<UpdatePINPage />}
                />
                <Route
                  path={Paths.PRIVATE.BANK_ACCOUNTS}
                  element={<BankAccountsPage />}
                />
                <Route
                  path={Paths.PRIVATE.TERMS_CONDITIONS}
                  element={<TermConditionsPage />}
                />
                <Route
                  path={Paths.PRIVATE.REFERRAL}
                  element={<ReferralPage />}
                />
              </Route>
              <Route
                path={Paths.PRIVATE.DEPOSIT_CALLBACK}
                element={<DepositCallbackPage />}
              />
            </Route>

            {/*PUBLIC ROUTES*/}
            <Route element={<PublicRouteLayout />}>
              <Route
                path={Paths.PUBLIC.ONBOARD_LOGIN}
                element={<OnboardingPage />}
              />
              <Route
                path={Paths.PUBLIC.ONBOARD}
                element={<OnboardingPage />}
              />
              <Route
                path={Paths.PUBLIC.LOGIN}
                element={<LoginPage />}
              />
              <Route
                path={Paths.PUBLIC.REGISTER}
                element={<RegisterPage />}
              />
              <Route
                path={Paths.PUBLIC.FORGOT_PIN}
                element={<ForgotPINPage />}
              />
              <Route
                path={Paths.PUBLIC.REFERRAL_CALLBACK}
                element={<ReferralCallbackPage />}
              />

              <Route
                path={Paths.PUBLIC.MAINTENANCE}
                element={<MaintenancePage />}
              />
            </Route>
            {/* 404 Route - Catch all unknown routes */}
            <Route
              path="*"
              element={
                <Navigate
                  to={Paths.PRIVATE.HOME}
                  replace
                />
              }
            />
          </Routes>
        </RootRouteLayout>
      </BrowserRouter>
    </>
  );
};

export default AppRouter;
