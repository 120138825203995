import { BackButton } from "@components";
import { Paths } from "@constants";
import { useGetRewardsInfo } from "@hooks";
import { t } from "i18next";
import { ButtonIcon, EmptyState, PageTitle, Skeleton, WalletTxMethod } from "kz-ui-sdk";
import { PropsWithChildren, useMemo } from "react";
import { AspectRatio } from "react-aspect-ratio";
import { MdInfoOutline } from "react-icons/md";
import { PiTreasureChest } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import { RewardBox } from "./components";

interface CashbackPageProps extends PropsWithChildren {}

const CashbackPage = ({}: CashbackPageProps) => {
  const navigate = useNavigate();
  const { data, isLoading, refetch } = useGetRewardsInfo();

  const boxStatus = useMemo(() => {
    return {
      isInstantAvailable: !!data?.[WalletTxMethod.InstantCashBack],
      isDailyAvailable: !!data?.[WalletTxMethod.DailyCashBack],
      isWeeklyAvailable: !!data?.[WalletTxMethod.WeeklyCashBack],
      isMonthlyAvailable: !!data?.[WalletTxMethod.MonthlyCashBack],
    };
  }, [data]);

  const isEmpty =
    !isLoading &&
    !boxStatus.isInstantAvailable &&
    !boxStatus.isDailyAvailable &&
    !boxStatus.isWeeklyAvailable &&
    !boxStatus.isMonthlyAvailable;

  const boxDataList = useMemo(() => {
    const dataResult = [];
    if (boxStatus.isInstantAvailable) {
      dataResult.push(data![WalletTxMethod.InstantCashBack]!);
    }
    if (boxStatus.isDailyAvailable) {
      dataResult.push(data![WalletTxMethod.DailyCashBack]!);
    }
    if (boxStatus.isWeeklyAvailable) {
      dataResult.push(data![WalletTxMethod.WeeklyCashBack]!);
    }
    if (boxStatus.isMonthlyAvailable) {
      dataResult.push(data![WalletTxMethod.MonthlyCashBack]!);
    }
    return dataResult;
  }, [data, boxStatus]);

  return (
    <>
      <PageTitle
        label={t("Cashback")}
        variant="gradient-purple"
        backButton={<BackButton />}
        rightButton={
          <ButtonIcon
            icon={<MdInfoOutline className="h-5 w-5 text-base" />}
            variant="ghost"
            onClick={() => {
              navigate(Paths.PRIVATE.CASHBACK_TNC);
            }}
          />
        }
        className="mb-2"
      />

      {/*EMPTY STATE*/}
      {isEmpty && (
        <EmptyState
          icon={<PiTreasureChest className="text-content-secondary h-8 w-8" />}
          title={t("no cashback available")}
          subTitle={t("please come back later")}
        />
      )}
      <div className="-mx-5 grid grid-cols-2 gap-2 px-2">
        {!isLoading && (
          <>
            {boxDataList.map((boxData, index) => (
              <RewardBox
                data={boxData}
                refetch={refetch}
                key={boxData.type}
                className="animate-fade-in"
                style={{
                  animationDelay: `${index * 200}ms`,
                  opacity: 0,
                }}
              />
            ))}
          </>
        )}
        {isLoading && (
          <>
            {Array.from({ length: 4 }).map((_, index) => (
              <AspectRatio
                ratio="167/180"
                key={index}
              >
                <Skeleton className="h-full w-full" />
              </AspectRatio>
            ))}
          </>
        )}
      </div>
    </>
  );
};

export default CashbackPage;
