import { cn } from "kz-ui-sdk";
import { useCallback } from "react";

interface RangeButtonProps {
  value: number; //[0 -> 1],
  onClick: (v: number) => void;
  disabled?: boolean;
}

const ACTIVE_CLASS = "bg-grdPrimary";

export default function RangeButton({ value, onClick, disabled = false }: RangeButtonProps) {
  const handleClick = useCallback(
    (v: number) => {
      if (disabled) return;
      onClick(v);
    },
    [disabled, onClick],
  );

  return (
    <div
      className={cn("flex flex-row gap-1.5 text-sm", {
        "cursor-not-allowed opacity-60": disabled,
      })}
    >
      {/*  */}
      <div
        className={cn("text-content-disabled w-1/4 font-bold", {
          "!text-content-base": value > 0,
        })}
        onClick={() => handleClick(0.25)}
      >
        <div
          className={cn("bg-content-disabled h-[8px] w-full rounded-s-lg", {
            [ACTIVE_CLASS]: value > 0,
          })}
        ></div>
        <div className="pt-2 text-center">25%</div>
      </div>
      {/*  */}
      <div
        className={cn("text-content-disabled w-1/4 font-bold", {
          "!text-content-base": value > 0.25,
        })}
        onClick={() => handleClick(0.5)}
      >
        <div
          className={cn("bg-content-disabled h-[8px] w-full", {
            [ACTIVE_CLASS]: value > 0.25,
          })}
        ></div>
        <div className="pt-2 text-center">50%</div>
      </div>
      {/*  */}
      <div
        className={cn("text-content-disabled w-1/4 font-bold", {
          "!text-content-base": value > 0.5,
        })}
        onClick={() => handleClick(0.75)}
      >
        <div
          className={cn("bg-content-disabled h-[8px] w-full", {
            [ACTIVE_CLASS]: value > 0.5,
          })}
        ></div>
        <div className="pt-2 text-center">75%</div>
      </div>
      {/*  */}
      <div
        className={cn("text-content-disabled w-1/4 font-bold", {
          "!text-content-base": value > 0.75,
        })}
        onClick={() => handleClick(1)}
      >
        <div
          className={cn("bg-content-disabled h-[8px] w-full rounded-e-lg", {
            [ACTIVE_CLASS]: value > 0.75,
          })}
        ></div>
        <div className="pt-2 text-center">100%</div>
      </div>
      {/*  */}
    </div>
  );
}
